import { Node, Edge } from 'reactflow';
import dagre from 'dagre';

const nodeWidth = 300;
const nodeHeight = 400;
const edgeSpacing = 50;
const boxPadding = 100;
const verticalSpacing = 40; // Spacing between parallel vertical routes

export function getLayoutedElements(
  nodes: Node[], 
  edges: Edge[], 
  direction: 'LR' | 'TB' = 'LR',
  spacing: number = 1
) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  // Group edges by harness
  const edgesByHarness = edges.reduce((acc, edge) => {
    const harnessId = edge.data?.harnessId;
    if (!acc[harnessId]) {
      acc[harnessId] = [];
    }
    acc[harnessId].push(edge);
    return acc;
  }, {} as Record<string, Edge[]>);

  // Calculate base spacing based on number of unique harnesses
  const numHarnesses = Object.keys(edgesByHarness).length;
  const baseSpacing = 150 * spacing;
  const harnessSpacing = Math.max(baseSpacing, baseSpacing * (numHarnesses / 2));

  dagreGraph.setGraph({ 
    rankdir: direction,
    nodesep: harnessSpacing,
    ranksep: 200 * spacing,
    edgesep: edgeSpacing * spacing,
    marginx: boxPadding,
    marginy: boxPadding,
    acyclicer: 'greedy',
    ranker: 'network-simplex'
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // Add edges with weights based on harness grouping
  edges.forEach((edge) => {
    const harnessId = edge.data?.harnessId;
    const weight = edges.filter(e => e.data?.harnessId === harnessId).length;
    
    dagreGraph.setEdge(edge.source, edge.target, {
      weight: weight,
      minlen: 2 // Ensure minimum distance between nodes
    });
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  // Group edges by vertical segments
  const verticalSegments = new Map<string, Edge[]>();
  edges.forEach(edge => {
    const sourceNode = layoutedNodes.find(n => n.id === edge.source);
    const targetNode = layoutedNodes.find(n => n.id === edge.target);
    if (!sourceNode || !targetNode) return;

    const midX = (sourceNode.position.x + targetNode.position.x) / 2;
    const key = `${Math.round(midX)}`;
    const existing = verticalSegments.get(key) || [];
    verticalSegments.set(key, [...existing, edge]);
  });

  // Create smart routing points for edges
  const layoutedEdges = edges.map(edge => {
    const sourceNode = layoutedNodes.find(n => n.id === edge.source);
    const targetNode = layoutedNodes.find(n => n.id === edge.target);
    const harnessId = edge.data?.harnessId;
    const harnessHue = (parseInt(harnessId, 36) * 137.508) % 360;
    
    if (!sourceNode || !targetNode) return edge;

    // Calculate control points to route around boxes
    const points = calculateSmartRoutingPoints(
      sourceNode, 
      targetNode, 
      edge, 
      layoutedNodes,
      verticalSegments,
      edges
    );
    
    return {
      ...edge,
      type: 'smoothstep',
      animated: true,
      style: {
        ...edge.style,
        strokeWidth: 2,
        stroke: `hsl(${harnessHue}, 70%, 50%)`,
      },
      data: {
        ...edge.data,
        points
      },
    };
  });

  return { nodes: layoutedNodes, edges: layoutedEdges };
}

function calculateSmartRoutingPoints(
  sourceNode: Node, 
  targetNode: Node, 
  edge: Edge, 
  allNodes: Node[],
  verticalSegments: Map<string, Edge[]>,
  allEdges: Edge[]
) {
  const sourceCenter = {
    x: sourceNode.position.x + nodeWidth / 2,
    y: sourceNode.position.y + nodeHeight / 2
  };
  const targetCenter = {
    x: targetNode.position.x + nodeWidth / 2,
    y: targetNode.position.y + nodeHeight / 2
  };

  // Find parallel edges with the same harness
  const midX = (sourceCenter.x + targetCenter.x) / 2;
  const segmentKey = `${Math.round(midX)}`;
  const parallelEdges = verticalSegments.get(segmentKey) || [];
  const parallelIndex = parallelEdges.findIndex(e => e.id === edge.id);
  const verticalOffset = parallelIndex * verticalSpacing;

  // Calculate path avoiding other boxes
  const points = [];
  const obstacles = allNodes.filter(n => 
    n.id !== sourceNode.id && 
    n.id !== targetNode.id &&
    isNodeInPath(n, sourceCenter, targetCenter)
  );

  // Calculate route based on harness ID to ensure consistent paths
  const harnessId = edge.data?.harnessId;
  const sameHarnessEdges = allEdges.filter(e => e.data?.harnessId === harnessId);
  const harnessIndex = sameHarnessEdges.findIndex(e => e.id === edge.id);
  const harnessOffset = harnessIndex * verticalSpacing;

  if (obstacles.length > 0) {
    // Route around obstacles with vertical offset
    const obstacleMargin = boxPadding * 1.5;
    const routeY = Math.min(sourceCenter.y, targetCenter.y) - obstacleMargin - harnessOffset;

    points.push(
      { x: sourceCenter.x + boxPadding, y: sourceCenter.y },
      { x: sourceCenter.x + boxPadding * 2, y: sourceCenter.y },
      { x: sourceCenter.x + boxPadding * 2, y: routeY },
      { x: targetCenter.x - boxPadding * 2, y: routeY },
      { x: targetCenter.x - boxPadding * 2, y: targetCenter.y },
      { x: targetCenter.x - boxPadding, y: targetCenter.y }
    );
  } else {
    // Direct path with harness-based offset
    const routeY = Math.min(sourceCenter.y, targetCenter.y) - boxPadding - harnessOffset;
    
    points.push(
      { x: sourceCenter.x + boxPadding, y: sourceCenter.y },
      { x: sourceCenter.x + boxPadding * 2, y: sourceCenter.y },
      { x: sourceCenter.x + boxPadding * 2, y: routeY },
      { x: targetCenter.x - boxPadding * 2, y: routeY },
      { x: targetCenter.x - boxPadding * 2, y: targetCenter.y },
      { x: targetCenter.x - boxPadding, y: targetCenter.y }
    );
  }

  return points;
}

function isNodeInPath(node: Node, start: { x: number; y: number }, end: { x: number; y: number }) {
  const nodeBox = {
    left: node.position.x - boxPadding,
    right: node.position.x + nodeWidth + boxPadding,
    top: node.position.y - boxPadding,
    bottom: node.position.y + nodeHeight + boxPadding
  };

  // Check if the line between start and end intersects with the node's bounding box
  const minX = Math.min(start.x, end.x);
  const maxX = Math.max(start.x, end.x);
  const minY = Math.min(start.y, end.y);
  const maxY = Math.max(start.y, end.y);

  return !(
    nodeBox.right < minX ||
    nodeBox.left > maxX ||
    nodeBox.bottom < minY ||
    nodeBox.top > maxY
  );
}