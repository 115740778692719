import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, ChevronRight, CheckCircle2 } from 'lucide-react';
import type { Procedure } from '../types';

interface SortableProcedureItemProps {
  procedure: Procedure;
}

export function SortableProcedureItem({
  procedure,
}: SortableProcedureItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: procedure.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
    opacity: isDragging ? 0.5 : 1,
  };

  const progress = (procedure.currentStep / procedure.totalSteps) * 100;

  return (
    <div ref={setNodeRef} style={style}>
      <div className="group relative flex items-center bg-gray-50 rounded-lg p-3 border border-gray-100 hover:border-gray-200 transition-all duration-200">
        <div
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-grab active:cursor-grabbing"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="text-gray-400" />
        </div>

        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center space-x-2">
              {procedure.isComplete ? (
                <CheckCircle2 className="text-green-500" size={16} />
              ) : (
                <ChevronRight className="text-blue-500" size={16} />
              )}
              <h4 className="text-sm font-medium text-gray-900 truncate">
                {procedure.name}
              </h4>
            </div>
            <span className="text-xs text-gray-600">
              Step {procedure.currentStep}/{procedure.totalSteps}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-1.5">
            <div
              className={`${
                procedure.isComplete ? 'bg-green-500' : 'bg-blue-500'
              } h-1.5 rounded-full transition-all duration-300`}
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}