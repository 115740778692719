import React from 'react';
import { CheckCircle, XCircle, X } from 'lucide-react';
import type { ImportResult } from '../../types';

interface ImportSummaryProps {
  result: ImportResult;
  onClose: () => void;
  onRollback?: () => void;
}

export function ImportSummary({ result, onClose, onRollback }: ImportSummaryProps) {
  const { successful, failed, total } = result;
  const hasErrors = failed.length > 0;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-bold text-gray-900">Import Summary</h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div className="flex items-center justify-center space-x-8">
            <div className="text-center">
              <div className="text-2xl font-bold text-gray-900">{total}</div>
              <div className="text-sm text-gray-600">Total</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-green-600">
                {successful.length}
              </div>
              <div className="text-sm text-gray-600">Successful</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-red-600">{failed.length}</div>
              <div className="text-sm text-gray-600">Failed</div>
            </div>
          </div>

          {successful.length > 0 && (
            <div>
              <h3 className="flex items-center space-x-2 text-green-600 font-medium mb-2">
                <CheckCircle size={20} />
                <span>Successfully Imported</span>
              </h3>
              <div className="bg-green-50 rounded-lg p-4 max-h-40 overflow-y-auto">
                <ul className="space-y-1">
                  {successful.map((proc) => (
                    <li key={proc.name} className="text-sm text-green-800">
                      {proc.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {failed.length > 0 && (
            <div>
              <h3 className="flex items-center space-x-2 text-red-600 font-medium mb-2">
                <XCircle size={20} />
                <span>Failed to Import</span>
              </h3>
              <div className="bg-red-50 rounded-lg p-4 max-h-40 overflow-y-auto">
                <ul className="space-y-2">
                  {failed.map((error) => (
                    <li key={error.name} className="text-sm">
                      <span className="font-medium text-red-800">
                        {error.name}:
                      </span>{' '}
                      <span className="text-red-600">{error.error}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="p-6 border-t bg-gray-50 flex justify-end space-x-3">
          {hasErrors && onRollback && (
            <button
              onClick={onRollback}
              className="px-4 py-2 text-red-600 hover:text-red-700 transition-colors"
            >
              Rollback Import
            </button>
          )}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}