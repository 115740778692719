import React from 'react';
import { WifiOff } from 'lucide-react';

export function OfflineIndicator() {
  return (
    <div className="fixed bottom-4 right-4 bg-yellow-50 text-yellow-800 px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2">
      <WifiOff size={16} />
      <span className="text-sm">
        Working offline. Changes will sync when connection is restored.
      </span>
    </div>
  );
}