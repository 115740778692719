import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Node,
  Edge,
  useNodesState,
  useEdgesState,
  Position,
  MarkerType,
  ReactFlowProvider,
  Controls,
  Background,
  BackgroundVariant,
  Handle,
  NodeProps,
} from 'reactflow';
import { Eye, EyeOff, Filter, Minimize2, Maximize2 } from 'lucide-react';
import dagre from 'dagre';
import type { Harness, ElectricalBox, Port, HarnessComponent } from '../types';
import { getLayoutedElements } from '../utils/diagramLayout';
import 'reactflow/dist/style.css';

const getPortStyle = (port: Port) => {
  const colors = {
    green: '#10B981',
    blue: '#3B82F6',
    black: '#1F2937',
    silver: '#9CA3AF',
  };

  const baseStyle = {
    width: port.shape === 'circle' ? '12px' : '16px',
    height: port.shape === 'circle' ? '12px' : '12px',
    backgroundColor: colors[port.color],
    border: `2px solid ${colors[port.color]}`,
  };

  if (port.shape === 'rectangle') {
    return {
      ...baseStyle,
      borderRadius: '2px',
    };
  }

  return {
    ...baseStyle,
    borderRadius: '50%',
  };
};

// Custom node for electrical boxes that contains ports
function ElectricalBoxNode({ data, id }: NodeProps) {
  const { box, ports, onSelectBox } = data;

  const getPortColor = (type: Port['type']) => {
    switch (type) {
      case 'input':
        return 'bg-blue-50 border-blue-200';
      case 'output':
        return 'bg-green-50 border-green-200';
      case 'bidirectional':
        return 'bg-purple-50 border-purple-200';
      default:
        return 'bg-gray-50 border-gray-200';
    }
  };

  return (
    <div 
      className="min-w-[300px] min-h-[200px] bg-white rounded-lg border-2 border-blue-200 shadow-lg p-4 cursor-pointer hover:border-blue-400 transition-colors"
      onClick={() => onSelectBox?.(id)}
    >
      <div className="text-lg font-semibold text-gray-900 mb-4 text-center border-b border-gray-200 pb-2">
        {box.name}
      </div>
      
      <div className="space-y-2">
        <div className="text-sm font-medium text-gray-600 mb-2">Ports</div>
        {ports.map((port) => (
          <div
            key={port.id}
            className={`relative rounded p-2 text-sm border ${getPortColor(port.type)}`}
          >
            <Handle
              type="target"
              position={Position[port.position]}
              id={`${port.id}-in`}
              style={getPortStyle(port)}
            />
            <div className="flex justify-between items-center">
              <span className="font-medium text-gray-700">{port.name}</span>
              <span className="text-xs text-gray-500">({port.type})</span>
            </div>
            <Handle
              type="source"
              position={Position[port.position]}
              id={`${port.id}-out`}
              style={getPortStyle(port)}
            />
          </div>
        ))}
      </div>

      {box.description && (
        <div className="mt-4 text-sm text-gray-600 border-t border-gray-100 pt-2">
          {box.description}
        </div>
      )}
    </div>
  );
}

const nodeTypes = {
  electricalBox: ElectricalBoxNode,
};

interface HarnessDiagramProps {
  harness: Harness;
  onUpdate: (harness: Harness) => void;
  onSelectBox?: (boxId: string) => void;
}

interface ConnectionControlsProps {
  harnesses: HarnessComponent[];
  electricalBoxes: ElectricalBox[];
  showAllConnections: boolean;
  selectedHarnessId: string | null;
  selectedBoxId: string | null;
  spacing: number;
  onSpacingChange: (value: number) => void;
  onToggleAllConnections: () => void;
  onSelectHarness: (harnessId: string | null) => void;
  onSelectBox: (boxId: string | null) => void;
}

function ConnectionControls({
  harnesses,
  electricalBoxes,
  showAllConnections,
  selectedHarnessId,
  selectedBoxId,
  spacing,
  onSpacingChange,
  onToggleAllConnections,
  onSelectHarness,
  onSelectBox,
}: ConnectionControlsProps) {
  return (
    <div className="absolute top-4 right-4 z-10 bg-white rounded-lg shadow-lg p-4 space-y-4">
      {/* Visibility Controls */}
      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">Visibility</h3>
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-600">Show All Connections</span>
          <button
            onClick={onToggleAllConnections}
            className={`p-2 rounded-lg transition-colors ${
              showAllConnections
                ? 'bg-blue-100 text-blue-600 hover:bg-blue-200'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
            title={showAllConnections ? 'Hide all connections' : 'Show all connections'}
          >
            {showAllConnections ? <Eye size={18} /> : <EyeOff size={18} />}
          </button>
        </div>
      </div>

      {/* Spacing Controls */}
      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">Node Spacing</h3>
        <div className="flex items-center space-x-2">
          <Minimize2 className="text-gray-400" size={14} />
          <input
            type="range"
            min="0.5"
            max="2"
            step="0.1"
            value={spacing}
            onChange={(e) => onSpacingChange(parseFloat(e.target.value))}
            className="w-24"
          />
          <Maximize2 className="text-gray-400" size={14} />
        </div>
      </div>

      {/* Filters */}
      {!showAllConnections && (
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Filters</h3>
          <div className="space-y-3">
            {/* Harness Filter */}
            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Filter by Harness
              </label>
              <select
                value={selectedHarnessId || ''}
                onChange={(e) => onSelectHarness(e.target.value || null)}
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All Harnesses</option>
                {harnesses.map((h) => (
                  <option key={h.id} value={h.id}>
                    {h.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Box Filter */}
            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Filter by Box
              </label>
              <select
                value={selectedBoxId || ''}
                onChange={(e) => onSelectBox(e.target.value || null)}
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All Boxes</option>
                {electricalBoxes.map((box) => (
                  <option key={box.id} value={box.id}>
                    {box.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function HarnessDiagramFlow({ harness, onSelectBox }: HarnessDiagramProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showAllConnections, setShowAllConnections] = useState(true);
  const [selectedHarnessId, setSelectedHarnessId] = useState<string | null>(null);
  const [selectedBoxId, setSelectedBoxId] = useState<string | null>(null);
  const [spacing, setSpacing] = useState(1);

  const createEdges = useCallback((connections: Harness['connections'], showConnections: boolean, filterHarnessId: string | null = null, filterBoxId: string | null = null) => {
    if (!showConnections) return [];
    
    const electricalBoxes = harness.electricalBoxes || [];
    const harnesses = harness.harnesses || [];
    
    return connections
      .filter(connection => {
        if (filterHarnessId && connection.harnessId !== filterHarnessId) return false;
        if (filterBoxId && connection.sourceBoxId !== filterBoxId && connection.targetBoxId !== filterBoxId) return false;
        return true;
      })
      .map(connection => {
        const sourceBox = electricalBoxes.find(b => b.id === connection.sourceBoxId);
        const targetBox = electricalBoxes.find(b => b.id === connection.targetBoxId);
        const connectedHarness = harnesses.find(h => h.id === connection.harnessId);

        if (sourceBox && targetBox && connectedHarness) {
          const sourcePort = sourceBox.ports.find(p => p.id === connection.sourcePortId);
          const targetPort = targetBox.ports.find(p => p.id === connection.targetPortId);

          if (sourcePort && targetPort) {
            const harnessHue = (parseInt(connection.harnessId, 36) * 137.508) % 360;
            return {
              id: connection.id,
              source: connection.sourceBoxId,
              target: connection.targetBoxId,
              sourceHandle: `${connection.sourcePortId}-out`,
              targetHandle: `${connection.targetPortId}-in`,
              type: 'smoothstep',
              animated: true,
              label: connectedHarness.name,
              labelStyle: { fill: '#6b7280', fontSize: 12 },
              style: { stroke: `hsl(${harnessHue}, 70%, 50%)`, strokeWidth: 2 },
              data: { harnessId: connection.harnessId },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: `hsl(${harnessHue}, 70%, 50%)`,
              },
            };
          }
        }
        return null;
      })
      .filter((edge): edge is Edge => edge !== null);
  }, [harness.electricalBoxes, harness.harnesses]);

  useEffect(() => {
    const electricalBoxes = harness.electricalBoxes || [];
    const connections = harness.connections || [];

    const initialNodes: Node[] = electricalBoxes.map(box => ({
      id: box.id,
      type: 'electricalBox',
      data: { 
        box,
        ports: box.ports,
        onSelectBox
      },
      position: { x: 0, y: 0 },
    }));

    const initialEdges = createEdges(
      connections,
      showAllConnections || selectedHarnessId !== null || selectedBoxId !== null,
      !showAllConnections ? selectedHarnessId : null,
      !showAllConnections ? selectedBoxId : null
    );

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges,
      'LR',
      spacing
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [
    harness,
    showAllConnections,
    selectedHarnessId,
    selectedBoxId,
    spacing,
    createEdges,
    setNodes,
    setEdges,
    onSelectBox
  ]);

  return (
    <>
      <ConnectionControls
        harnesses={harness.harnesses}
        electricalBoxes={harness.electricalBoxes}
        showAllConnections={showAllConnections}
        selectedHarnessId={selectedHarnessId}
        selectedBoxId={selectedBoxId}
        spacing={spacing}
        onSpacingChange={setSpacing}
        onToggleAllConnections={() => {
          setShowAllConnections(!showAllConnections);
          setSelectedHarnessId(null);
          setSelectedBoxId(null);
        }}
        onSelectHarness={setSelectedHarnessId}
        onSelectBox={setSelectedBoxId}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        minZoom={0.1}
        maxZoom={1.5}
        nodesDraggable={false}
        elementsSelectable={false}
        panOnDrag={true}
        panOnScroll={true}
        zoomOnScroll={true}
        preventScrolling={false}
      >
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </>
  );
}

export function HarnessDiagram(props: HarnessDiagramProps) {
  return (
    <div className="h-[calc(100vh-16rem)] bg-white rounded-lg shadow-lg relative">
      <ReactFlowProvider>
        <HarnessDiagramFlow {...props} />
      </ReactFlowProvider>
    </div>
  );
}