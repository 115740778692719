import React, { useRef } from 'react';
import { Upload } from 'lucide-react';
import { parseCSV } from '../../utils/csvParser';
import { validateProcedures } from '../../utils/procedureValidator';
import type { CSVProcedure } from '../../types';

interface CSVImportButtonProps {
  onDataParsed: (data: CSVProcedure[]) => void;
  onError: (error: string) => void;
}

export function CSVImportButton({ onDataParsed, onError }: CSVImportButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      onError('Please select a valid CSV file');
      return;
    }

    try {
      const text = await file.text();
      const parsedData = await parseCSV(text);
      const validationResult = validateProcedures(parsedData);

      if (validationResult.errors.length > 0) {
        onError(`Validation errors found:\n${validationResult.errors.join('\n')}`);
        return;
      }

      onDataParsed(validationResult.procedures);
    } catch (err) {
      onError(err instanceof Error ? err.message : 'Failed to parse CSV file');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={handleFileSelect}
        className="hidden"
      />
      <button
        onClick={() => fileInputRef.current?.click()}
        className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        <Upload size={20} />
        <span>Import Procedures</span>
      </button>
    </div>
  );
}