import React, { useState, useRef } from 'react';
import { Edit2, Settings, Cable, Table2 } from 'lucide-react';
import { HarnessConfig } from './HarnessConfig';
import { HarnessDiagram } from './HarnessDiagram';
import { HarnessTable } from './HarnessTableView/HarnessTable';
import type { Harness, ElectricalBox, Port, HarnessComponent } from '../types';

interface HarnessWorkspaceProps {
  harness: Harness;
  onUpdate: (harness: Harness) => void;
}

export function HarnessWorkspace({ harness, onUpdate }: HarnessWorkspaceProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [activeView, setActiveView] = useState<'config' | 'diagram' | 'table'>('config');
  const boxRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [editForm, setEditForm] = useState({
    name: harness.name,
    description: harness.description || ''
  });

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdate({
      ...harness,
      name: editForm.name,
      description: editForm.description,
      updatedAt: new Date()
    });
    setIsEditing(false);
  };

  const handleSelectBox = (boxId: string) => {
    setActiveView('config');
    setTimeout(() => {
      const element = boxRefs.current[boxId];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const handleTableSave = (connections: Array<{
    sourceBoxName: string;
    sourcePortName: string;
    targetBoxName: string;
    targetPortName: string;
    harnessName: string;
  }>) => {
    // Create or find electrical boxes
    const newBoxes = new Map<string, ElectricalBox>();
    const newPorts = new Map<string, Port>();
    const newHarnesses = new Map<string, HarnessComponent>();

    connections.forEach(conn => {
      // Handle source box
      if (!newBoxes.has(conn.sourceBoxName)) {
        newBoxes.set(conn.sourceBoxName, {
          id: crypto.randomUUID(),
          name: conn.sourceBoxName,
          harnesses: [],
          ports: [],
          order: newBoxes.size,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      // Handle target box
      if (!newBoxes.has(conn.targetBoxName)) {
        newBoxes.set(conn.targetBoxName, {
          id: crypto.randomUUID(),
          name: conn.targetBoxName,
          harnesses: [],
          ports: [],
          order: newBoxes.size,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      // Handle source port
      const sourcePortKey = `${conn.sourceBoxName}:${conn.sourcePortName}`;
      if (!newPorts.has(sourcePortKey)) {
        newPorts.set(sourcePortKey, {
          id: crypto.randomUUID(),
          name: conn.sourcePortName,
          type: 'bidirectional',
          position: 'left',
          order: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      // Handle target port
      const targetPortKey = `${conn.targetBoxName}:${conn.targetPortName}`;
      if (!newPorts.has(targetPortKey)) {
        newPorts.set(targetPortKey, {
          id: crypto.randomUUID(),
          name: conn.targetPortName,
          type: 'bidirectional',
          position: 'right',
          order: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      // Handle harness
      if (!newHarnesses.has(conn.harnessName)) {
        newHarnesses.set(conn.harnessName, {
          id: crypto.randomUUID(),
          name: conn.harnessName,
          order: newHarnesses.size,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    });

    // Build the updated harness structure
    const electricalBoxes = Array.from(newBoxes.values()).map(box => ({
      ...box,
      ports: Array.from(newPorts.values()).filter(port => {
        const [boxName] = Array.from(newPorts.entries()).find(([, p]) => p.id === port.id)?.[0].split(':') || [];
        return boxName === box.name;
      }),
    }));

    const newConnections = connections.map(conn => {
      const sourceBox = electricalBoxes.find(box => box.name === conn.sourceBoxName);
      const targetBox = electricalBoxes.find(box => box.name === conn.targetBoxName);
      const sourcePort = sourceBox?.ports.find(port => port.name === conn.sourcePortName);
      const targetPort = targetBox?.ports.find(port => port.name === conn.targetPortName);
      const connHarness = Array.from(newHarnesses.values()).find(h => h.name === conn.harnessName);

      return {
        id: crypto.randomUUID(),
        sourceBoxId: sourceBox!.id,
        sourcePortId: sourcePort!.id,
        targetBoxId: targetBox!.id,
        targetPortId: targetPort!.id,
        harnessId: connHarness!.id,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    });

    onUpdate({
      ...harness,
      electricalBoxes,
      harnesses: Array.from(newHarnesses.values()),
      connections: newConnections,
      updatedAt: new Date(),
    });
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
        {isEditing ? (
          <form onSubmit={handleEditSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Harness Name
              </label>
              <input
                type="text"
                value={editForm.name}
                onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={editForm.description}
                onChange={(e) => setEditForm({ ...editForm, description: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows={3}
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="flex flex-wrap justify-between items-start gap-4">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold text-gray-900 truncate">{harness.name}</h2>
                {harness.description && (
                  <p className="text-gray-600 mt-1 line-clamp-2">{harness.description}</p>
                )}
              </div>
              <div className="flex flex-wrap gap-3">
                <button
                  onClick={() => setIsEditing(true)}
                  className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <Edit2 size={16} />
                  <span>Edit Details</span>
                </button>
                <div className="flex rounded-lg border border-gray-200 divide-x">
                  <button
                    onClick={() => setActiveView('config')}
                    className={`px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'config'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors`}
                  >
                    <Settings size={16} />
                    <span>Config</span>
                  </button>
                  <button
                    onClick={() => setActiveView('diagram')}
                    className={`px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'diagram'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors`}
                  >
                    <Cable size={16} />
                    <span>Diagram</span>
                  </button>
                  <button
                    onClick={() => setActiveView('table')}
                    className={`px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'table'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors`}
                  >
                    <Table2 size={16} />
                    <span>Table</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {activeView === 'config' ? (
        <HarnessConfig 
          harness={harness} 
          onUpdate={onUpdate}
          boxRefs={boxRefs}
        />
      ) : activeView === 'diagram' ? (
        <HarnessDiagram 
          harness={harness} 
          onUpdate={onUpdate}
          onSelectBox={handleSelectBox}
        />
      ) : (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <HarnessTable
            harness={harness}
            onSave={handleTableSave}
          />
        </div>
      )}
    </div>
  );
}