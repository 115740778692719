import React, { useRef } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import { validateAssemblyCSV } from '../../utils/csv/assemblyCSVValidator';
import { MAX_FILE_SIZE } from '../../utils/constants';

interface AssemblyCSVUploadProps {
  onFileSelect: (file: File) => void;
  error?: string;
}

export function AssemblyCSVUpload({ onFileSelect, error }: AssemblyCSVUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    // Validate file type
    if (!file.name.endsWith('.csv')) {
      alert('Please select a valid CSV file');
      return;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      alert(`File size must be less than ${MAX_FILE_SIZE / 1024 / 1024}MB`);
      return;
    }

    try {
      await validateAssemblyCSV(file);
      onFileSelect(file);
    } catch (err) {
      alert(err instanceof Error ? err.message : 'Failed to validate CSV file');
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current?.click()}
          className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Upload size={20} />
          <span>Upload Assembly CSV</span>
        </button>
      </div>

      {error && (
        <div className="flex items-center space-x-2 text-red-600 text-sm">
          <AlertCircle size={16} />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}