import React, { useState } from 'react';
import { Orbit, Mail, AlertCircle, Rocket } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export function AuthScreen() {
  const { login, signup, error: authError } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (isLogin) {
        await login(email, password);
      } else {
        await signup(email, password);
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      console.error('Auth error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const displayError = error || authError;

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-indigo-900">
      {/* Header */}
      <div className="w-full bg-gradient-to-r from-indigo-600 via-blue-600 to-indigo-700 text-white shadow-lg">
        <div className="container mx-auto">
          <div className="flex items-center px-6 py-4">
            <div className="flex items-center space-x-4">
              <div className="relative w-10 h-10">
                <Orbit 
                  className="text-white/80 animate-spin-slow absolute inset-0" 
                  size={40} 
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-5 h-5 flex items-center justify-center">
                    <Rocket className="text-white" size={16} />
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold tracking-wider bg-gradient-to-r from-white via-blue-100 to-white bg-clip-text text-transparent">
                  CONFIGONAUT
                </h1>
                <p className="text-xs text-blue-200 uppercase tracking-widest">Mission Control</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 flex items-center justify-center" style={{ height: 'calc(100vh - 72px)' }}>
        <div className="w-full max-w-md">
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-2xl p-8 border border-white/20">
            <h2 className="text-2xl font-bold text-center text-white mb-8">
              {isLogin ? 'Launch Sequence' : 'New Mission'}
            </h2>

            {displayError && (
              <div className="mb-6 p-4 bg-red-500/20 border border-red-500/30 rounded-lg flex items-center space-x-2">
                <AlertCircle className="text-red-400 flex-shrink-0" size={20} />
                <p className="text-sm text-red-200">{displayError}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-blue-200 mb-2">
                  Mission Control ID
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-white/50"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-blue-200 mb-2">
                  Access Code
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-white/50"
                  placeholder="Enter your password"
                  required
                  minLength={6}
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-medium rounded-lg transition-all disabled:opacity-50 flex items-center justify-center space-x-2"
              >
                {isLoading ? (
                  <>
                    <Orbit className="animate-spin h-5 w-5" />
                    <span>Initiating...</span>
                  </>
                ) : (
                  <>
                    <Rocket className="h-5 w-5" />
                    <span>{isLogin ? 'Launch Mission' : 'Begin New Mission'}</span>
                  </>
                )}
              </button>
            </form>

            <p className="mt-6 text-sm text-center text-blue-200">
              {isLogin ? "No mission clearance? " : "Already have clearance? "}
              <button
                onClick={() => setIsLogin(!isLogin)}
                className="text-blue-400 hover:text-blue-300 font-medium"
              >
                {isLogin ? 'Request Access' : 'Access Mission Control'}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}