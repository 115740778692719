import React, { useState, useEffect } from 'react';
import { Plus, Save, Trash2, PlusCircle, MinusCircle } from 'lucide-react';
import { AssemblyCSVUpload } from './AssemblyCSVUpload';
import { AssemblyCSVPreview } from './AssemblyCSVPreview';
import type { Assembly, Procedure } from '../../types';
import type { AssemblyCSVData } from '../../types/csv';
import { validateAssemblyData } from '../../utils/csv/assemblyCSVValidator';
import { parseAssemblyCSV } from '../../utils/csv/assemblyCSVParser';

interface AssemblyTableProps {
  assemblies: Assembly[];
  procedures: Procedure[];
  onSave: (rows: Array<{
    assemblyName: string;
    childAssemblies: string[];
    procedures: string[];
  }>) => void;
}

interface TableRow {
  id: string;
  assemblyName: string;
  childAssemblies: string[];
  procedures: string[];
}

export function AssemblyTable({ assemblies, procedures, onSave }: AssemblyTableProps) {
  const [rows, setRows] = useState<TableRow[]>([]);
  const [csvData, setCSVData] = useState<AssemblyCSVData[] | null>(null);
  const [csvValidation, setCSVValidation] = useState<ValidationResult | null>(null);
  const [uploadError, setUploadError] = useState<string>();

  useEffect(() => {
    // Convert existing assemblies to table rows
    const existingRows = assemblies.map(assembly => ({
      id: assembly.id,
      assemblyName: assembly.name,
      childAssemblies: assembly.subAssemblies?.map(sub => {
        const childAssembly = assemblies.find(a => a.id === sub.id);
        return childAssembly?.name || '';
      }) || [],
      procedures: assembly.procedures.map(p => p.name),
    }));

    setRows(existingRows);
  }, [assemblies]);

  const handleAddRow = () => {
    setRows([...rows, {
      id: crypto.randomUUID(),
      assemblyName: '',
      childAssemblies: [''],
      procedures: [''],
    }]);
  };

  const handleRemoveRow = (id: string) => {
    setRows(rows.filter(row => row.id !== id));
  };

  const handleAssemblyNameChange = (id: string, value: string) => {
    setRows(rows.map(row => 
      row.id === id ? { ...row, assemblyName: value } : row
    ));
  };

  const handleAddChildAssembly = (rowId: string) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? { ...row, childAssemblies: [...row.childAssemblies, ''] }
        : row
    ));
  };

  const handleRemoveChildAssembly = (rowId: string, index: number) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? { ...row, childAssemblies: row.childAssemblies.filter((_, i) => i !== index) }
        : row
    ));
  };

  const handleChildAssemblyChange = (rowId: string, index: number, value: string) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? {
            ...row,
            childAssemblies: row.childAssemblies.map((child, i) => 
              i === index ? value : child
            ),
          }
        : row
    ));
  };

  const handleAddProcedure = (rowId: string) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? { ...row, procedures: [...row.procedures, ''] }
        : row
    ));
  };

  const handleRemoveProcedure = (rowId: string, index: number) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? { ...row, procedures: row.procedures.filter((_, i) => i !== index) }
        : row
    ));
  };

  const handleProcedureChange = (rowId: string, index: number, value: string) => {
    setRows(rows.map(row => 
      row.id === rowId 
        ? {
            ...row,
            procedures: row.procedures.map((proc, i) => 
              i === index ? value : proc
            ),
          }
        : row
    ));
  };

  const handleSave = () => {
    // Filter out empty values and create new procedures/assemblies if needed
    const processedRows = rows.map(row => ({
      assemblyName: row.assemblyName,
      childAssemblies: row.childAssemblies.filter(Boolean),
      procedures: row.procedures.filter(Boolean),
    }));

    onSave(processedRows);
  };

  const handleFileSelect = async (file: File) => {
    try {
      const parsedData = await parseAssemblyCSV(file);
      const validation = validateAssemblyData(
        parsedData,
        assemblies.map(a => a.name)
      );
      setCSVData(parsedData);
      setCSVValidation(validation);
      setUploadError(undefined);
    } catch (err) {
      setUploadError(err instanceof Error ? err.message : 'Failed to process CSV file');
      setCSVData(null);
      setCSVValidation(null);
    }
  };

  const handleImportConfirm = () => {
    if (!csvData) return;
    onSave(csvData);
    setCSVData(null);
    setCSVValidation(null);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Assembly Table</h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddRow}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus size={16} />
            <span>Add Row</span>
          </button>
          <AssemblyCSVUpload 
            onFileSelect={handleFileSelect}
            error={uploadError}
          />
          <button
            onClick={handleSave}
            className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            <Save size={16} />
            <span>Save Changes</span>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Assembly Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Child Assemblies
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Procedures
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row) => (
              <tr key={row.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.assemblyName}
                    onChange={(e) => handleAssemblyNameChange(row.id, e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Assembly name"
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-2">
                    {row.childAssemblies.map((child, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={child}
                          onChange={(e) => handleChildAssemblyChange(row.id, index, e.target.value)}
                          className="flex-1 px-2 py-1 border border-gray-300 rounded-md"
                          placeholder="Child assembly name"
                        />
                        <button
                          onClick={() => handleRemoveChildAssembly(row.id, index)}
                          className="text-red-500 hover:text-red-700 transition-colors"
                        >
                          <MinusCircle size={16} />
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={() => handleAddChildAssembly(row.id)}
                      className="flex items-center space-x-1 text-sm text-blue-600 hover:text-blue-700"
                    >
                      <PlusCircle size={14} />
                      <span>Add Child Assembly</span>
                    </button>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="space-y-2">
                    {row.procedures.map((proc, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={proc}
                          onChange={(e) => handleProcedureChange(row.id, index, e.target.value)}
                          className="flex-1 px-2 py-1 border border-gray-300 rounded-md"
                          placeholder="Procedure name"
                        />
                        <button
                          onClick={() => handleRemoveProcedure(row.id, index)}
                          className="text-red-500 hover:text-red-700 transition-colors"
                        >
                          <MinusCircle size={16} />
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={() => handleAddProcedure(row.id)}
                      className="flex items-center space-x-1 text-sm text-blue-600 hover:text-blue-700"
                    >
                      <PlusCircle size={14} />
                      <span>Add Procedure</span>
                    </button>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleRemoveRow(row.id)}
                    className="text-red-500 hover:text-red-700 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {csvData && csvValidation && (
        <AssemblyCSVPreview
          data={csvData}
          validation={csvValidation}
          onConfirm={handleImportConfirm}
          onCancel={() => {
            setCSVData(null);
            setCSVValidation(null);
          }}
        />
      )}
    </div>
  );
}