import React, { useState, useEffect } from 'react';
import { Plus, Save, Trash2 } from 'lucide-react';
import type { Harness } from '../../types';

interface HarnessTableProps {
  harness: Harness;
  onSave: (connections: Array<{
    sourceBoxName: string;
    sourcePortName: string;
    targetBoxName: string;
    targetPortName: string;
    harnessName: string;
  }>) => void;
}

interface TableRow {
  id: string;
  sourceBoxName: string;
  sourcePortName: string;
  targetBoxName: string;
  targetPortName: string;
  harnessName: string;
}

export function HarnessTable({ harness, onSave }: HarnessTableProps) {
  const [rows, setRows] = useState<TableRow[]>([]);

  useEffect(() => {
    // Convert existing connections to table rows
    const existingRows = harness.connections?.map(conn => {
      const sourceBox = harness.electricalBoxes.find(box => box.id === conn.sourceBoxId);
      const targetBox = harness.electricalBoxes.find(box => box.id === conn.targetBoxId);
      const sourcePort = sourceBox?.ports.find(port => port.id === conn.sourcePortId);
      const targetPort = targetBox?.ports.find(port => port.id === conn.targetPortId);
      const connectedHarness = harness.harnesses.find(h => h.id === conn.harnessId);

      return {
        id: conn.id,
        sourceBoxName: sourceBox?.name || '',
        sourcePortName: sourcePort?.name || '',
        targetBoxName: targetBox?.name || '',
        targetPortName: targetPort?.name || '',
        harnessName: connectedHarness?.name || '',
      };
    }) || [];

    setRows(existingRows);
  }, [harness]);

  const handleAddRow = () => {
    setRows([...rows, {
      id: crypto.randomUUID(),
      sourceBoxName: '',
      sourcePortName: '',
      targetBoxName: '',
      targetPortName: '',
      harnessName: '',
    }]);
  };

  const handleRemoveRow = (id: string) => {
    setRows(rows.filter(row => row.id !== id));
  };

  const handleRowChange = (id: string, field: keyof TableRow, value: string) => {
    setRows(rows.map(row => 
      row.id === id ? { ...row, [field]: value } : row
    ));
  };

  const handleSave = () => {
    onSave(rows);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Connection Table</h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddRow}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus size={16} />
            <span>Add Row</span>
          </button>
          <button
            onClick={handleSave}
            className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            <Save size={16} />
            <span>Save Changes</span>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Electrical Box
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Port
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Connected to Box
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Connected to Port
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Harness
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row) => (
              <tr key={row.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.sourceBoxName}
                    onChange={(e) => handleRowChange(row.id, 'sourceBoxName', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Box name"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.sourcePortName}
                    onChange={(e) => handleRowChange(row.id, 'sourcePortName', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Port name"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.targetBoxName}
                    onChange={(e) => handleRowChange(row.id, 'targetBoxName', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Connected box"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.targetPortName}
                    onChange={(e) => handleRowChange(row.id, 'targetPortName', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Connected port"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={row.harnessName}
                    onChange={(e) => handleRowChange(row.id, 'harnessName', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    placeholder="Harness name"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleRemoveRow(row.id)}
                    className="text-red-500 hover:text-red-700 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}