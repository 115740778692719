import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AssemblyNode } from './AssemblyTree/AssemblyNode';
import { Controls } from './AssemblyTree/Controls';
import type { Assembly } from '../types';

interface StandaloneAssemblyTreeProps {
  assemblies: Assembly[];
  onSelectAssembly?: (assemblyId: string) => void;
  onSelectProcedure?: (procedureId: string) => void;
  selectedAssemblyId?: string | null;
  selectedProcedureId?: string | null;
}

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;
const ZOOM_STEP = 0.1;

export function StandaloneAssemblyTree({ 
  assemblies, 
  onSelectAssembly, 
  onSelectProcedure,
  selectedAssemblyId,
  selectedProcedureId 
}: StandaloneAssemblyTreeProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const rootAssemblies = assemblies.filter(assembly => 
    !assemblies.some(a => a.subAssemblies?.some(sub => sub.id === assembly.id))
  );

  const sortedRootAssemblies = [...rootAssemblies].sort((a, b) => a.order - b.order);

  const handleZoom = useCallback((delta: number) => {
    setScale(prevScale => {
      const newScale = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, prevScale + delta));
      return Number(newScale.toFixed(1));
    });
  }, []);

  const resetZoom = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) {
      e.preventDefault();
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
      if (containerRef.current) {
        containerRef.current.style.cursor = 'grabbing';
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      e.preventDefault();
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;

      // Update position with smooth animation
      setPosition({
        x: newX,
        y: newY
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    if (containerRef.current) {
      containerRef.current.style.cursor = 'grab';
    }
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();

    if (e.ctrlKey || e.metaKey) {
      // Handle zooming
      const delta = e.deltaY * -0.01;
      handleZoom(delta);
    } else {
      // Handle scrolling
      const scrollSpeed = 1;
      setPosition(prev => ({
        x: prev.x - e.deltaX * scrollSpeed,
        y: prev.y - e.deltaY * scrollSpeed
      }));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, [handleZoom]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === '=' || e.key === '+') {
        handleZoom(ZOOM_STEP);
      } else if (e.key === '-' || e.key === '_') {
        handleZoom(-ZOOM_STEP);
      } else if (e.key === '0') {
        resetZoom();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleZoom]);

  return (
    <div className="flex flex-col h-[calc(100vh-16rem)] bg-gray-50 rounded-lg border border-gray-200">
      <Controls 
        assemblies={assemblies}
        scale={scale}
        isHorizontal={isHorizontal}
        onZoomIn={() => handleZoom(ZOOM_STEP)}
        onZoomOut={() => handleZoom(-ZOOM_STEP)}
        onReset={resetZoom}
        onToggleLayout={() => setIsHorizontal(!isHorizontal)}
      />
      <div 
        ref={containerRef}
        className="flex-1 p-6 overflow-hidden relative cursor-grab select-none"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div 
          className={`absolute ${isHorizontal ? 'flex space-x-6' : 'space-y-6'}`}
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transformOrigin: '0 0',
            transition: isDragging ? 'none' : 'transform 0.1s ease-out',
            willChange: 'transform'
          }}
        >
          {sortedRootAssemblies.map(assembly => (
            <AssemblyNode
              key={assembly.id}
              assembly={assembly}
              assemblies={assemblies}
              level={0}
              onSelectAssembly={onSelectAssembly}
              onSelectProcedure={onSelectProcedure}
              isSelected={selectedAssemblyId === assembly.id}
              selectedProcedureId={selectedProcedureId}
              isRoot={true}
              isHorizontal={isHorizontal}
            />
          ))}
          {sortedRootAssemblies.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              <p>No assemblies found</p>
              <p className="text-sm mt-2">Create an assembly to get started</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}