import React from 'react';
import { Cable } from 'lucide-react';
import { StatCard } from './StatCard';
import { StatItem } from './StatItem';
import type { Harness } from '../../../types';

interface HarnessStatsProps {
  harnesses: Harness[];
}

export function HarnessStats({ harnesses }: HarnessStatsProps) {
  const totalHarnesses = harnesses.length;
  const totalConnections = harnesses.reduce((acc, h) => acc + (h.connections?.length || 0), 0);
  const totalBoxes = harnesses.reduce((acc, h) => acc + (h.electricalBoxes?.length || 0), 0);

  return (
    <StatCard title="Harness Overview" icon={<Cable className="text-indigo-600" size={24} />}>
      <StatItem label="Total Harnesses" value={totalHarnesses} />
      <StatItem label="Total Connections" value={totalConnections} />
      <StatItem label="Electrical Boxes" value={totalBoxes} />
    </StatCard>
  );
}