import { Assembly, Procedure } from '../types';

interface AssemblyNode {
  id: string;
  name: string;
  description?: string;
  level: number;
  isParent: boolean;
  procedures: Procedure[];
  progress: {
    completed: number;
    total: number;
    percentage: number;
  };
  children: AssemblyNode[];
}

function calculateProgress(assembly: Assembly, allAssemblies: Assembly[]) {
  let completed = assembly.procedures.filter(p => p.isComplete).length;
  let total = assembly.procedures.length;

  const childAssemblies = assembly.subAssemblies
    ?.map(sub => allAssemblies.find(a => a.id === sub.id))
    .filter((a): a is Assembly => a !== undefined);

  if (childAssemblies) {
    childAssemblies.forEach(child => {
      const childProgress = calculateProgress(child, allAssemblies);
      completed += childProgress.completed;
      total += childProgress.total;
    });
  }

  const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;
  return { completed, total, percentage };
}

function buildAssemblyTree(
  assembly: Assembly,
  allAssemblies: Assembly[],
  level: number = 0
): AssemblyNode {
  const progress = calculateProgress(assembly, allAssemblies);
  
  const childAssemblies = assembly.subAssemblies
    ?.map(sub => allAssemblies.find(a => a.id === sub.id))
    .filter((a): a is Assembly => a !== undefined);

  const children = childAssemblies
    ? childAssemblies.map(child => buildAssemblyTree(child, allAssemblies, level + 1))
    : [];

  return {
    id: assembly.id,
    name: assembly.name,
    description: assembly.description,
    level,
    isParent: Boolean(assembly.isParent),
    procedures: assembly.procedures,
    progress,
    children
  };
}

function generateIndentation(level: number): string {
  return '  '.repeat(level);
}

function formatProcedure(procedure: Procedure, level: number): string {
  const indent = generateIndentation(level);
  const status = procedure.isComplete ? '✓' : procedure.currentStep > 0 ? '⋯' : '○';
  return `${indent}${status} ${procedure.name} (${procedure.currentStep}/${procedure.totalSteps} steps)`;
}

function formatProgress({ completed, total, percentage }: AssemblyNode['progress']): string {
  return `${completed}/${total} complete (${percentage}%)`;
}

function assemblyToText(node: AssemblyNode): string {
  const indent = generateIndentation(node.level);
  const typeIndicator = node.isParent ? '📦' : '🔧';
  const lines: string[] = [];

  // Assembly header
  lines.push(`${indent}${typeIndicator} ${node.name}`);
  if (node.description) {
    lines.push(`${indent}  Description: ${node.description}`);
  }
  lines.push(`${indent}  Progress: ${formatProgress(node.progress)}`);

  // Procedures
  if (node.procedures.length > 0) {
    lines.push(`${indent}  Procedures:`);
    node.procedures.forEach(proc => {
      lines.push(formatProcedure(proc, node.level + 2));
    });
  }

  // Child assemblies
  if (node.children.length > 0) {
    lines.push(`${indent}  Sub-assemblies:`);
    node.children.forEach(child => {
      lines.push(assemblyToText(child));
    });
  }

  return lines.join('\n');
}

export function generateAssemblyExport(assemblies: Assembly[]): string {
  const rootAssemblies = assemblies.filter(assembly => 
    !assemblies.some(a => a.subAssemblies?.some(sub => sub.id === assembly.id))
  );

  const sortedRootAssemblies = [...rootAssemblies].sort((a, b) => a.order - b.order);
  
  const lines = [
    'Assembly Tree Export',
    '===================',
    `Generated: ${new Date().toLocaleString()}`,
    '',
    'Legend:',
    '  📦 Parent Assembly',
    '  🔧 Assembly',
    '  ✓ Completed Procedure',
    '  ⋯ In Progress Procedure',
    '  ○ Pending Procedure',
    '',
    'Structure:',
    ''
  ];

  sortedRootAssemblies.forEach(assembly => {
    const tree = buildAssemblyTree(assembly, assemblies);
    lines.push(assemblyToText(tree));
    lines.push('');
  });

  return lines.join('\n');
}