import React from 'react';
import { Construction, Rocket, Wrench } from 'lucide-react';

export function QualityUnderConstruction() {
  return (
    <div className="min-h-[calc(100vh-8rem)] flex items-center justify-center">
      <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-2xl">
        <div className="flex justify-center mb-6">
          <div className="relative">
            <Construction className="w-20 h-20 text-indigo-600" />
            <div className="absolute -top-2 -right-2 bg-white rounded-full p-1">
              <Wrench className="w-6 h-6 text-yellow-500 animate-pulse" />
            </div>
          </div>
        </div>
        
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          Quality Module Coming Soon
        </h1>
        
        <p className="text-gray-600 mb-6">
          Our engineering team is hard at work building the Quality Control module. 
          This feature will help ensure mission-critical standards are met across all assemblies.
        </p>
        
        <div className="inline-flex items-center space-x-2 text-sm text-indigo-600">
          <Rocket size={16} />
          <span>Launching in future update</span>
        </div>
      </div>
    </div>
  );
}