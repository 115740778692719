export function handleOfflineState(error: Error) {
  if (error.message.includes('Could not reach Cloud Firestore backend')) {
    console.warn('Operating in offline mode. Changes will be synced when connection is restored.');
    return true;
  }
  return false;
}

export function isOfflineError(error: Error) {
  return error.message.includes('Could not reach Cloud Firestore backend');
}