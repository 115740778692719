import React from 'react';

interface ErrorScreenProps {
  error: string | null;
}

export function ErrorScreen({ error }: ErrorScreenProps) {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-50 flex items-center justify-center">
      <div className="text-red-600 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-4">
        {error}
      </div>
    </div>
  );
}