import React from 'react';
import { SatelliteWorkspace } from '../SatelliteWorkspace';
import { HarnessWorkspace } from '../HarnessWorkspace';
import { QualityUnderConstruction } from '../quality/QualityUnderConstruction';
import { WelcomeDashboard } from '../dashboard/WelcomeDashboard';
import { SettingsPanel } from '../settings/SettingsPanel';
import { EmptyState } from '../common/EmptyState';
import type { Satellite, Harness } from '../../types';

interface WorkspaceContainerProps {
  activeSection: 'dashboard' | 'satellites' | 'harnesses' | 'quality' | 'settings';
  selectedSatellite: Satellite | undefined;
  selectedHarness: Harness | undefined;
  satellites: Satellite[];
  harnesses: Harness[];
  currentUser: any;
  onUpdateSatellite: (satellite: Satellite) => void;
  onUpdateHarness: (harness: Harness) => void;
}

export function WorkspaceContainer({
  activeSection,
  selectedSatellite,
  selectedHarness,
  satellites,
  harnesses,
  currentUser,
  onUpdateSatellite,
  onUpdateHarness,
}: WorkspaceContainerProps) {
  if (activeSection === 'settings') {
    return <SettingsPanel />;
  }

  if (activeSection === 'quality') {
    return <QualityUnderConstruction />;
  }

  if (activeSection === 'dashboard') {
    return (
      <div className="flex-1 p-6">
        <WelcomeDashboard 
          satellites={satellites}
          harnesses={harnesses}
          currentUser={currentUser}
        />
      </div>
    );
  }

  return (
    <div className="flex-1">
      {activeSection === 'satellites' ? (
        selectedSatellite ? (
          <SatelliteWorkspace
            satellite={selectedSatellite}
            onUpdate={onUpdateSatellite}
          />
        ) : (
          <EmptyState type="assembly" />
        )
      ) : selectedHarness ? (
        <HarnessWorkspace
          harness={selectedHarness}
          onUpdate={onUpdateHarness}
        />
      ) : (
        <EmptyState type="harness" />
      )}
    </div>
  );
}