import React from 'react';
import { Orbit } from 'lucide-react';

export function LoadingScreen() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-50 flex items-center justify-center">
      <div className="relative">
        <Orbit className="animate-spin h-12 w-12 text-indigo-600" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-2 h-2 bg-indigo-600 rounded-full"></div>
        </div>
      </div>
    </div>
  );
}