import React, { useState, useEffect } from 'react';
import { X, ArrowLeftRight } from 'lucide-react';
import type { Harness, PortConnection, Port } from '../types';

interface ConnectionEditorProps {
  harness: Harness;
  connection: PortConnection;
  onUpdate: (updatedConnection: PortConnection) => void;
  onCancel: () => void;
}

export function ConnectionEditor({
  harness,
  connection,
  onUpdate,
  onCancel,
}: ConnectionEditorProps) {
  const [sourceBoxId, setSourceBoxId] = useState(connection.sourceBoxId);
  const [sourcePortId, setSourcePortId] = useState(connection.sourcePortId);
  const [targetBoxId, setTargetBoxId] = useState(connection.targetBoxId);
  const [targetPortId, setTargetPortId] = useState(connection.targetPortId);
  const [selectedHarnessId, setSelectedHarnessId] = useState(connection.harnessId);

  const sourceBox = harness.electricalBoxes.find(box => box.id === sourceBoxId);
  const targetBox = harness.electricalBoxes.find(box => box.id === targetBoxId);
  const sourcePort = sourceBox?.ports.find(port => port.id === sourcePortId);
  const targetPort = targetBox?.ports.find(port => port.id === targetPortId);

  const isPortCompatible = (port1: Port, port2: Port) => {
    if (port1.type === 'bidirectional' || port2.type === 'bidirectional') return true;
    if (port1.type === 'output' && port2.type === 'input') return true;
    if (port1.type === 'input' && port2.type === 'output') return true;
    return false;
  };

  const getAvailablePorts = (boxId: string, isSource: boolean) => {
    const box = harness.electricalBoxes.find(b => b.id === boxId);
    if (!box) return [];

    return box.ports.filter(port => {
      // Allow the current connection's ports
      if (
        (isSource && port.id === connection.sourcePortId) ||
        (!isSource && port.id === connection.targetPortId)
      ) {
        return true;
      }

      // Check if port is already used in other connections
      const isUsedInOtherConnection = harness.connections?.some(conn => 
        conn.id !== connection.id && (
          conn.sourcePortId === port.id ||
          conn.targetPortId === port.id
        )
      );

      if (isUsedInOtherConnection) return false;

      // Check compatibility with the other selected port
      const otherPort = isSource
        ? targetBox?.ports.find(p => p.id === targetPortId)
        : sourceBox?.ports.find(p => p.id === sourcePortId);

      if (!otherPort) return true;
      return isPortCompatible(port, otherPort);
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const updatedConnection: PortConnection = {
      ...connection,
      sourceBoxId,
      sourcePortId,
      targetBoxId,
      targetPortId,
      harnessId: selectedHarnessId,
      updatedAt: new Date(),
    };

    onUpdate(updatedConnection);
  };

  const isSelfReferential = sourceBoxId === targetBoxId;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Edit Connection</h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Source Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Source Box
          </label>
          <select
            value={sourceBoxId}
            onChange={(e) => {
              setSourceBoxId(e.target.value);
              setSourcePortId('');
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            {harness.electricalBoxes.map(box => (
              <option key={box.id} value={box.id}>{box.name}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Source Port
          </label>
          <select
            value={sourcePortId}
            onChange={(e) => setSourcePortId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select a port...</option>
            {getAvailablePorts(sourceBoxId, true).map(port => (
              <option key={port.id} value={port.id}>
                {port.name} ({port.type})
              </option>
            ))}
          </select>
        </div>

        {/* Target Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Target Box
          </label>
          <select
            value={targetBoxId}
            onChange={(e) => {
              setTargetBoxId(e.target.value);
              setTargetPortId('');
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            {harness.electricalBoxes.map(box => (
              <option key={box.id} value={box.id}>{box.name}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Target Port
          </label>
          <select
            value={targetPortId}
            onChange={(e) => setTargetPortId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select a port...</option>
            {getAvailablePorts(targetBoxId, false).map(port => (
              <option key={port.id} value={port.id}>
                {port.name} ({port.type})
              </option>
            ))}
          </select>
        </div>

        {/* Harness Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Harness
          </label>
          <select
            value={selectedHarnessId}
            onChange={(e) => setSelectedHarnessId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select a harness...</option>
            {harness.harnesses.map(h => (
              <option key={h.id} value={h.id}>{h.name}</option>
            ))}
          </select>
        </div>

        {/* Self-referential warning */}
        {isSelfReferential && (
          <div className="flex items-center space-x-2 p-3 bg-yellow-50 border border-yellow-200 rounded-md text-yellow-800">
            <ArrowLeftRight size={16} />
            <span className="text-sm">
              This is a self-referential connection within the same box
            </span>
          </div>
        )}

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!sourcePortId || !targetPortId || !selectedHarnessId}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Update Connection
          </button>
        </div>
      </form>
    </div>
  );
}