import React from 'react';
import { Shield, Key } from 'lucide-react';

export function SecuritySettings() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center space-x-2">
        <Shield className="text-indigo-600" size={20} />
        <span>Security</span>
      </h3>

      <div className="space-y-4">
        <div>
          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">Two-Factor Authentication</label>
              <p className="text-sm text-gray-500">Add an extra layer of security to your account</p>
            </div>
            <button className="px-4 py-2 border border-indigo-600 text-indigo-600 rounded-lg hover:bg-indigo-50 transition-colors">
              Enable
            </button>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">Change Password</label>
              <p className="text-sm text-gray-500">Update your password regularly</p>
            </div>
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors">
              <Key size={16} className="mr-2" />
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}