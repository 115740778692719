import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { 
  GripVertical, 
  Trash2, 
  Edit2, 
  Layers, 
  ChevronRight, 
  ChevronDown, 
  ChevronUp,
  ArrowRight 
} from 'lucide-react';
import type { Assembly, Procedure } from '../types';
import { SortableProcedureItem } from './SortableProcedureItem';

interface SortableAssemblyCardProps {
  assembly: Assembly;
  allAssemblies: Assembly[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onReorderProcedures: (assemblyId: string, procedures: Procedure[]) => void;
  isSelected?: boolean;
}

const getLevelColors = (level: number, isParent: boolean) => {
  const colors = [
    { from: 'from-blue-50', to: 'to-indigo-50', hover: 'hover:from-blue-100 hover:to-indigo-100', border: 'border-blue-200' },
    { from: 'from-purple-50', to: 'to-fuchsia-50', hover: 'hover:from-purple-100 hover:to-fuchsia-100', border: 'border-purple-200' },
    { from: 'from-cyan-50', to: 'to-sky-50', hover: 'hover:from-cyan-100 hover:to-sky-100', border: 'border-cyan-200' },
    { from: 'from-emerald-50', to: 'to-teal-50', hover: 'hover:from-emerald-100 hover:to-teal-100', border: 'border-emerald-200' },
    { from: 'from-amber-50', to: 'to-orange-50', hover: 'hover:from-amber-100 hover:to-orange-100', border: 'border-amber-200' },
  ];

  const colorIndex = level % colors.length;
  const baseColors = colors[colorIndex];

  if (isParent) {
    return {
      background: `bg-gradient-to-br ${baseColors.from} ${baseColors.to}`,
      hover: baseColors.hover,
      border: `border-2 ${baseColors.border}`,
      text: 'text-gray-900',
      subtext: 'text-gray-600'
    };
  }

  const nextIndex = (colorIndex + 1) % colors.length;
  const nextColors = colors[nextIndex];
  
  return {
    background: `bg-gradient-to-br ${nextColors.from} ${nextColors.to}`,
    hover: nextColors.hover,
    border: `border ${nextColors.border}`,
    text: 'text-gray-800',
    subtext: 'text-gray-600'
  };
};

const calculateLevel = (assemblyId: string, assemblies: Assembly[], visited = new Set<string>()): number => {
  if (visited.has(assemblyId)) return 0;
  visited.add(assemblyId);
  
  let maxParentLevel = 0;
  assemblies.forEach(a => {
    if (a.subAssemblies?.some(sub => sub.id === assemblyId)) {
      const parentLevel = calculateLevel(a.id, assemblies, visited);
      maxParentLevel = Math.max(maxParentLevel, parentLevel + 1);
    }
  });
  return maxParentLevel;
};

const calculateProgress = (assembly: Assembly, allAssemblies: Assembly[]): { completed: number; total: number } => {
  // If assembly has no procedures and no children, consider it complete
  if (assembly.procedures.length === 0 && (!assembly.subAssemblies || assembly.subAssemblies.length === 0)) {
    return { completed: 0, total: 0 };
  }

  let completed = assembly.procedures.filter(p => p.isComplete).length;
  let total = assembly.procedures.length;

  const childAssemblies = assembly.subAssemblies
    ?.map(sub => allAssemblies.find(a => a.id === sub.id))
    .filter((a): a is Assembly => a !== undefined);

  if (childAssemblies) {
    childAssemblies.forEach(child => {
      const childProgress = calculateProgress(child, allAssemblies);
      completed += childProgress.completed;
      total += childProgress.total;
    });
  }

  return { completed, total };
};

interface ChildAssemblyProgressProps {
  assembly: Assembly;
  allAssemblies: Assembly[];
  level: number;
}

const ChildAssemblyProgress: React.FC<ChildAssemblyProgressProps> = ({ assembly, allAssemblies, level }) => {
  const progress = calculateProgress(assembly, allAssemblies);
  const progressPercentage = progress.total > 0 ? Math.round((progress.completed / progress.total) * 100) : 0;
  const showProgress = progress.total > 0;

  return (
    <div className="pl-4 border-l-2 border-gray-200 mt-2">
      <div className="flex items-center space-x-2">
        <ArrowRight className="text-gray-400" size={16} />
        <span className="text-sm font-medium text-gray-700">{assembly.name}</span>
        {showProgress && (
          <span className="text-xs text-gray-500">
            ({progress.completed}/{progress.total} complete)
          </span>
        )}
      </div>
      {showProgress && (
        <div className="mt-1 w-full bg-gray-200 rounded-full h-1.5">
          <div
            className="bg-blue-500 h-1.5 rounded-full transition-all duration-300"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      )}
    </div>
  );
};

export function SortableAssemblyCard({
  assembly,
  allAssemblies,
  onDelete,
  onEdit,
  onReorderProcedures,
  isSelected = false,
}: SortableAssemblyCardProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showChildren, setShowChildren] = useState(true);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: assembly.id });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
    opacity: isDragging ? 0.5 : 1,
  };

  const level = calculateLevel(assembly.id, allAssemblies);
  const colors = getLevelColors(level, assembly.isParent);

  const progress = calculateProgress(assembly, allAssemblies);
  const progressPercentage = progress.total > 0 ? Math.round((progress.completed / progress.total) * 100) : 0;
  const showProgress = progress.total > 0;

  const childAssemblies = assembly.subAssemblies
    ?.map(sub => allAssemblies.find(a => a.id === sub.id))
    .filter((a): a is Assembly => a !== undefined);

  const handleProcedureDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = assembly.procedures.findIndex(p => p.id === active.id);
      const newIndex = assembly.procedures.findIndex(p => p.id === over.id);

      const newProcedures = arrayMove(assembly.procedures, oldIndex, newIndex).map(
        (proc, index) => ({
          ...proc,
          order: index,
        })
      );
      onReorderProcedures(assembly.id, newProcedures);
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this assembly?')) {
      onDelete(assembly.id);
    }
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div 
        className={`
          group relative rounded-lg shadow-lg p-6 transition-all hover:shadow-xl
          ${colors.background}
          ${colors.hover}
          ${colors.border}
          ${isSelected ? 'ring-2 ring-blue-500 shadow-lg' : ''}
        `}
      >
        <div
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-grab active:cursor-grabbing"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="text-gray-400" />
        </div>

        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center space-x-3">
            {assembly.isParent ? (
              <Layers className="text-blue-600" size={24} />
            ) : (
              <ChevronRight className="text-cyan-600" size={24} />
            )}
            <div>
              <div className="flex items-center space-x-2">
                <h3 className={`text-xl font-bold ${colors.text}`}>
                  {assembly.name}
                </h3>
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="p-1 rounded-full hover:bg-white/50 transition-colors"
                >
                  {isExpanded ? (
                    <ChevronDown className="text-gray-500" size={20} />
                  ) : (
                    <ChevronUp className="text-gray-500" size={20} />
                  )}
                </button>
              </div>
              <div className="flex items-center space-x-2 mt-1">
                <span className={`text-sm ${colors.subtext}`}>
                  Level {level}
                </span>
                {assembly.description && (
                  <>
                    <span className="text-gray-300">•</span>
                    <span className={`text-sm ${colors.subtext}`}>
                      {assembly.description}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => onEdit(assembly.id)}
              className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
              title="Edit assembly"
            >
              <Edit2 size={20} />
            </button>
            <button
              onClick={handleDelete}
              className="p-2 text-red-400 hover:text-red-500 transition-colors"
              title="Delete assembly"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>

        {showProgress && (
          <div className="space-y-2 mb-4">
            <div className="flex justify-between text-sm text-gray-600">
              <span>Total Progress</span>
              <span>{progress.completed}/{progress.total} complete</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>
        )}

        {isExpanded && (
          <>
            {assembly.procedures.length > 0 && (
              <div className="mb-4">
                <div className="flex items-center justify-between mb-2">
                  <h4 className={`text-sm font-medium ${colors.text}`}>Procedures:</h4>
                  <span className="text-xs text-gray-500">
                    {assembly.procedures.filter(p => p.isComplete).length}/{assembly.procedures.length} complete
                  </span>
                </div>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleProcedureDragEnd}
                >
                  <SortableContext
                    items={assembly.procedures}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="space-y-2">
                      {assembly.procedures.map((procedure) => (
                        <SortableProcedureItem
                          key={procedure.id}
                          procedure={procedure}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
            )}

            {childAssemblies && childAssemblies.length > 0 && (
              <div className="mt-4">
                <div className="flex items-center justify-between mb-2">
                  <h4 className={`text-sm font-medium ${colors.text}`}>Child Assemblies:</h4>
                  <button
                    onClick={() => setShowChildren(!showChildren)}
                    className="text-xs text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    {showChildren ? 'Hide' : 'Show'} ({childAssemblies.length})
                  </button>
                </div>
                {showChildren && (
                  <div className="space-y-2">
                    {childAssemblies.map((child) => (
                      <ChildAssemblyProgress
                        key={child.id}
                        assembly={child}
                        allAssemblies={allAssemblies}
                        level={level + 1}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        <div className="mt-4 text-xs text-gray-500 flex justify-between">
          <span>Created: {new Date(assembly.createdAt).toLocaleDateString()}</span>
          <span>Updated: {new Date(assembly.updatedAt).toLocaleDateString()}</span>
        </div>
      </div>
    </div>
  );
}