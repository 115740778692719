import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import type { Harness } from '../types';

interface HarnessFormProps {
  harness?: Harness | null;
  onSubmit: (name: string, description?: string) => void;
  onCancel: () => void;
}

export function HarnessForm({ harness, onSubmit, onCancel }: HarnessFormProps) {
  const [name, setName] = useState(harness?.name || '');
  const [description, setDescription] = useState(harness?.description || '');

  useEffect(() => {
    if (harness) {
      setName(harness.name);
      setDescription(harness.description || '');
    }
  }, [harness]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(name.trim(), description.trim() || undefined);
    setName('');
    setDescription('');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">
          {harness ? 'Edit Harness' : 'Add Harness'}
        </h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Harness Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter harness name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description (Optional)
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            rows={3}
            placeholder="Enter harness description"
          />
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {harness ? 'Update Harness' : 'Add Harness'}
          </button>
        </div>
      </form>
    </div>
  );
}