import React from 'react';

interface StatItemProps {
  icon?: React.ReactNode;
  label: string;
  value: number;
}

export function StatItem({ icon, label, value }: StatItemProps) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-2">
        {icon && icon}
        <span className="text-gray-600">{label}</span>
      </div>
      <span className="font-semibold">{value}</span>
    </div>
  );
}