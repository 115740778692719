import React from 'react';
import { getTimeOfDay } from '../../utils/dateUtils';

interface WelcomeHeaderProps {
  currentUser: any;
}

export function WelcomeHeader({ currentUser }: WelcomeHeaderProps) {
  return (
    <div className="bg-gradient-to-r from-indigo-600 to-blue-600 rounded-lg shadow-lg p-6 text-white">
      <h1 className="text-2xl font-bold mb-2">
        Good {getTimeOfDay()}, {currentUser?.email}
      </h1>
      <p className="text-blue-100">
        Welcome to your mission control dashboard. Here's an overview of your spacecraft configurations.
      </p>
    </div>
  );
}