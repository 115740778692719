import React, { useState } from 'react';
import { X, Plus, Trash2 } from 'lucide-react';
import type { Harness, ElectricalBox, Port, HarnessComponent } from '../types';

interface PortConnectionFormProps {
  harness: Harness;
  sourceBoxId: string;
  sourcePortId: string;
  onSubmit: (connections: Array<{ targetBoxId: string; targetPortId: string }>, harnessId: string) => void;
  onCancel: () => void;
}

interface PortConnection {
  targetBoxId: string;
  targetPortId: string;
}

export function PortConnectionForm({
  harness,
  sourceBoxId,
  sourcePortId,
  onSubmit,
  onCancel,
}: PortConnectionFormProps) {
  const [connections, setConnections] = useState<PortConnection[]>([
    { targetBoxId: '', targetPortId: '' }
  ]);
  const [selectedHarnessId, setSelectedHarnessId] = useState('');

  const sourceBox = harness.electricalBoxes.find(box => box.id === sourceBoxId);
  const sourcePort = sourceBox?.ports.find(port => port.id === sourcePortId);

  const availableTargetBoxes = harness.electricalBoxes.filter(box => box.id !== sourceBoxId);

  const getAvailableTargetPorts = (boxId: string, currentConnectionIndex: number) => {
    const targetBox = harness.electricalBoxes.find(box => box.id === boxId);
    if (!targetBox || !sourcePort) return [];

    return targetBox.ports.filter(port => {
      // Check if port is already selected in other connections in this form
      const isSelectedInOtherConnections = connections.some((conn, index) => 
        index !== currentConnectionIndex && 
        conn.targetBoxId === boxId && 
        conn.targetPortId === port.id
      );
      
      if (isSelectedInOtherConnections) return false;

      // Check if port is already connected to the source port in existing connections
      const isConnectedInExisting = harness.connections?.some(conn => 
        (conn.sourcePortId === sourcePortId && conn.targetPortId === port.id) ||
        (conn.targetPortId === sourcePortId && conn.sourcePortId === port.id)
      );
      
      if (isConnectedInExisting) return false;

      // Check port type compatibility
      if (port.type === 'bidirectional' || sourcePort.type === 'bidirectional') return true;
      if (sourcePort.type === 'output' && port.type === 'input') return true;
      if (sourcePort.type === 'input' && port.type === 'output') return true;
      
      return false;
    });
  };

  const handleAddConnection = () => {
    setConnections([...connections, { targetBoxId: '', targetPortId: '' }]);
  };

  const handleRemoveConnection = (index: number) => {
    setConnections(connections.filter((_, i) => i !== index));
  };

  const handleConnectionChange = (index: number, field: keyof PortConnection, value: string) => {
    const newConnections = [...connections];
    newConnections[index] = {
      ...newConnections[index],
      [field]: value,
      // Reset targetPortId when changing box
      ...(field === 'targetBoxId' && { targetPortId: '' }),
    };
    setConnections(newConnections);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (connections.every(conn => conn.targetBoxId && conn.targetPortId) && selectedHarnessId) {
      onSubmit(connections, selectedHarnessId);
    }
  };

  const isValid = connections.every(conn => conn.targetBoxId && conn.targetPortId) && selectedHarnessId;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Connect Ports</h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Source
          </label>
          <div className="px-3 py-2 border border-gray-200 rounded-md bg-gray-50">
            <p className="font-medium text-gray-900">{sourceBox?.name}</p>
            <p className="text-sm text-gray-600">
              Port: {sourcePort?.name} ({sourcePort?.type})
            </p>
          </div>
        </div>

        <div className="space-y-4">
          {connections.map((connection, index) => {
            const availablePorts = connection.targetBoxId 
              ? getAvailableTargetPorts(connection.targetBoxId, index)
              : [];

            return (
              <div key={index} className="relative p-4 bg-gray-50 rounded-lg border border-gray-200">
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveConnection(index)}
                    className="absolute top-2 right-2 p-1 text-red-400 hover:text-red-500 transition-colors"
                    title="Remove connection"
                  >
                    <Trash2 size={16} />
                  </button>
                )}
                
                <div className="space-y-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Target Box
                    </label>
                    <select
                      value={connection.targetBoxId}
                      onChange={(e) => handleConnectionChange(index, 'targetBoxId', e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    >
                      <option value="">Select a box...</option>
                      {availableTargetBoxes.map(box => (
                        <option key={box.id} value={box.id}>{box.name}</option>
                      ))}
                    </select>
                  </div>

                  {connection.targetBoxId && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Target Port
                      </label>
                      <select
                        value={connection.targetPortId}
                        onChange={(e) => handleConnectionChange(index, 'targetPortId', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                      >
                        <option value="">Select a port...</option>
                        {availablePorts.map(port => (
                          <option key={port.id} value={port.id}>
                            {port.name} ({port.type})
                          </option>
                        ))}
                      </select>
                      {availablePorts.length === 0 && connection.targetBoxId && (
                        <p className="mt-1 text-sm text-amber-600">
                          No compatible ports available in this box
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <button
          type="button"
          onClick={handleAddConnection}
          className="w-full p-2 border-2 border-dashed border-gray-300 rounded-lg text-gray-500 hover:text-blue-500 hover:border-blue-500 transition-colors flex items-center justify-center space-x-2"
        >
          <Plus size={20} />
          <span>Add Another Port Connection</span>
        </button>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Harness
          </label>
          <select
            value={selectedHarnessId}
            onChange={(e) => setSelectedHarnessId(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select a harness...</option>
            {harness.harnesses.map(h => (
              <option key={h.id} value={h.id}>{h.name}</option>
            ))}
          </select>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!isValid}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Connect Ports
          </button>
        </div>
      </form>
    </div>
  );
}