import React, { useState, useEffect } from 'react';
import { SortableSatelliteList } from '../SortableSatelliteList';
import { HarnessList } from '../HarnessList';
import { CollapseButton } from '../common/CollapseButton';
import type { Satellite, Harness } from '../../types';

interface ListPanelProps {
  activeSection: 'dashboard' | 'satellites' | 'harnesses';
  satellites: Satellite[];
  harnesses: Harness[];
  selectedSatelliteId: string | null;
  selectedHarnessId: string | null;
  onSelectSatellite: (id: string) => void;
  onSelectHarness: (id: string) => void;
  onSaveSatellite: (satellite: Satellite) => void;
  onDeleteSatellite: (id: string) => void;
  onSaveHarness: (harness: Harness) => void;
  onDeleteHarness: (id: string) => void;
}

export function ListPanel({
  activeSection,
  satellites,
  harnesses,
  selectedSatelliteId,
  selectedHarnessId,
  onSelectSatellite,
  onSelectHarness,
  onSaveSatellite,
  onDeleteSatellite,
  onSaveHarness,
  onDeleteHarness,
}: ListPanelProps) {
  const [isCollapsed, setIsCollapsed] = useState(true); // Start collapsed by default

  // Reset collapse state when switching to dashboard
  useEffect(() => {
    if (activeSection === 'dashboard') {
      setIsCollapsed(true);
    }
  }, [activeSection]);

  // Only render if we're not in the quality section
  if (activeSection === 'quality') {
    return null;
  }

  return (
    <div className="flex">
      {/* Main panel */}
      <div 
        className={`
          relative bg-white rounded-lg shadow-lg flex-shrink-0 transition-all duration-300 ease-in-out
          ${isCollapsed ? 'w-0 opacity-0 -translate-x-4' : 'w-64 opacity-100 translate-x-0'}
        `}
      >
        <div className={`${isCollapsed ? 'invisible' : 'visible'} w-64 overflow-hidden`}>
          {activeSection === 'satellites' ? (
            <SortableSatelliteList
              satellites={satellites}
              selectedSatelliteId={selectedSatelliteId}
              onSelect={onSelectSatellite}
              onAdd={(name, description) => {
                const newSatellite = {
                  id: crypto.randomUUID(),
                  name,
                  description,
                  status: 'planned',
                  procedures: [],
                  assemblies: [],
                  order: satellites.length,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
                onSaveSatellite(newSatellite);
              }}
              onDelete={onDeleteSatellite}
              onDuplicate={(id) => {
                const satellite = satellites.find((s) => s.id === id);
                if (!satellite) return;

                const newSatellite = {
                  ...satellite,
                  id: crypto.randomUUID(),
                  name: `${satellite.name} (Copy)`,
                  order: satellites.length,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
                onSaveSatellite(newSatellite);
              }}
              onReorder={(reorderedSatellites) => {
                reorderedSatellites.forEach((satellite) => {
                  onSaveSatellite(satellite);
                });
              }}
            />
          ) : (
            <HarnessList
              harnesses={harnesses}
              selectedHarnessId={selectedHarnessId}
              onSelect={onSelectHarness}
              onAdd={(name, description) => {
                const newHarness = {
                  id: crypto.randomUUID(),
                  name,
                  description,
                  harnesses: [],
                  electricalBoxes: [],
                  order: harnesses.length,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
                onSaveHarness(newHarness);
              }}
              onDelete={onDeleteHarness}
              onDuplicate={(id) => {
                const harness = harnesses.find((h) => h.id === id);
                if (!harness) return;

                const newHarness = {
                  ...harness,
                  id: crypto.randomUUID(),
                  name: `${harness.name} (Copy)`,
                  order: harnesses.length,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
                onSaveHarness(newHarness);
              }}
              onUpdate={onSaveHarness}
            />
          )}
        </div>
      </div>

      {/* Collapse button container */}
      <div className="relative flex-shrink-0 -ml-3 z-10">
        <CollapseButton 
          isCollapsed={isCollapsed} 
          onClick={() => setIsCollapsed(!isCollapsed)}
          direction={isCollapsed ? 'right' : 'left'}
        />
      </div>

      {/* Spacer when panel is open */}
      {!isCollapsed && <div className="w-4" />}
    </div>
  );
}