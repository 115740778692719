import React from 'react';
import { 
  LayoutPanelLeft, 
  LayoutPanelTop, 
  ZoomIn, 
  ZoomOut, 
  RotateCcw 
} from 'lucide-react';
import { ExportButton } from '../ExportButton';
import type { Assembly } from '../../types';

interface ControlsProps {
  assemblies: Assembly[];
  scale: number;
  isHorizontal: boolean;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onReset: () => void;
  onToggleLayout: () => void;
}

export function Controls({
  assemblies,
  scale,
  isHorizontal,
  onZoomIn,
  onZoomOut,
  onReset,
  onToggleLayout
}: ControlsProps) {
  return (
    <div className="flex-none px-6 py-4 border-b border-gray-200 flex justify-between items-center">
      <h2 className="text-lg font-medium text-gray-900">Assembly Tree</h2>
      <div className="flex items-center space-x-3">
        <ExportButton assemblies={assemblies} />
        <div className="flex items-center bg-white rounded-lg border border-gray-200 p-1">
          <button
            onClick={onZoomOut}
            className="p-1.5 text-gray-600 hover:text-gray-900 transition-colors rounded hover:bg-gray-50"
            title="Zoom out (-)"
          >
            <ZoomOut size={18} />
          </button>
          <div className="px-2 min-w-[3rem] text-center">
            <span className="text-sm text-gray-600">{Math.round(scale * 100)}%</span>
          </div>
          <button
            onClick={onZoomIn}
            className="p-1.5 text-gray-600 hover:text-gray-900 transition-colors rounded hover:bg-gray-50"
            title="Zoom in (+)"
          >
            <ZoomIn size={18} />
          </button>
          <button
            onClick={onReset}
            className="p-1.5 text-gray-600 hover:text-gray-900 transition-colors rounded hover:bg-gray-50 ml-1"
            title="Reset zoom (0)"
          >
            <RotateCcw size={18} />
          </button>
        </div>
        <button
          onClick={onToggleLayout}
          className="p-2 text-gray-600 hover:text-gray-900 transition-colors rounded-lg hover:bg-gray-100"
          title={isHorizontal ? "Switch to vertical layout" : "Switch to horizontal layout"}
        >
          {isHorizontal ? (
            <LayoutPanelTop size={20} />
          ) : (
            <LayoutPanelLeft size={20} />
          )}
        </button>
      </div>
    </div>
  );
}