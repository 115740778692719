import React, { useState } from 'react';
import { MainLayout } from './components/layout/MainLayout';
import { AuthScreen } from './components/AuthScreen';
import { LoadingScreen } from './components/common/LoadingScreen';
import { ErrorScreen } from './components/common/ErrorScreen';
import { OfflineIndicator } from './components/common/OfflineIndicator';
import { WorkspaceContainer } from './components/workspace/WorkspaceContainer';
import { ListPanel } from './components/workspace/ListPanel';
import { useAuth } from './contexts/AuthContext';
import { useFirestore } from './hooks/useFirestore';
import { useHarnesses } from './hooks/useHarnesses';
import { SECTIONS } from './utils/constants';

type Section = 'satellites' | 'harnesses' | 'quality';

function App() {
  const { currentUser, logout } = useAuth();
  const { 
    satellites, 
    isLoading: satellitesLoading, 
    error: satellitesError,
    isOffline: satellitesOffline,
    saveSatellite, 
    deleteSatellite 
  } = useFirestore();
  
  const { 
    harnesses, 
    isLoading: harnessesLoading, 
    error: harnessesError,
    isOffline: harnessesOffline,
    saveHarness, 
    deleteHarness 
  } = useHarnesses();

  const [selectedSatelliteId, setSelectedSatelliteId] = useState<string | null>(null);
  const [selectedHarnessId, setSelectedHarnessId] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<Section>(SECTIONS.ASSEMBLIES);

  if (!currentUser) {
    return <AuthScreen />;
  }

  if (satellitesLoading || harnessesLoading) {
    return <LoadingScreen />;
  }

  if (satellitesError || harnessesError) {
    return <ErrorScreen error={satellitesError || harnessesError} />;
  }

  const selectedSatellite = satellites.find((s) => s.id === selectedSatelliteId);
  const selectedHarness = harnesses.find((h) => h.id === selectedHarnessId);
  const isOffline = satellitesOffline || harnessesOffline;

  return (
    <>
      <MainLayout
        activeSection={activeSection}
        onSectionChange={setActiveSection}
        currentUser={currentUser}
        onLogout={logout}
      >
        <div className="flex h-full">
          {activeSection !== 'quality' && (
            <ListPanel
              activeSection={activeSection}
              satellites={satellites}
              harnesses={harnesses}
              selectedSatelliteId={selectedSatelliteId}
              selectedHarnessId={selectedHarnessId}
              onSelectSatellite={setSelectedSatelliteId}
              onSelectHarness={setSelectedHarnessId}
              onSaveSatellite={saveSatellite}
              onDeleteSatellite={deleteSatellite}
              onSaveHarness={saveHarness}
              onDeleteHarness={deleteHarness}
            />
          )}
          <WorkspaceContainer
            activeSection={activeSection}
            selectedSatellite={selectedSatellite}
            selectedHarness={selectedHarness}
            onUpdateSatellite={saveSatellite}
            onUpdateHarness={saveHarness}
          />
        </div>
      </MainLayout>
      {isOffline && <OfflineIndicator />}
    </>
  );
}

export default App;