import React from 'react';
import { BarChart2 } from 'lucide-react';
import { StatCard } from './StatCard';
import type { Satellite } from '../../../types';

interface QualityOverviewProps {
  satellites: Satellite[];
}

export function QualityOverview({ satellites }: QualityOverviewProps) {
  const totalAssemblies = satellites.length;
  const completedAssemblies = satellites.filter(s => s.status === 'complete').length;
  const completionPercentage = totalAssemblies > 0
    ? Math.round((completedAssemblies / totalAssemblies) * 100)
    : 0;

  return (
    <StatCard title="Quality Metrics" icon={<BarChart2 className="text-indigo-600" size={24} />}>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-gray-600">Assembly Completion</span>
          <span className="font-semibold">{completionPercentage}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-indigo-600 h-2.5 rounded-full"
            style={{ width: `${completionPercentage}%` }}
          />
        </div>
      </div>
    </StatCard>
  );
}