import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { ProcedureFormData, Procedure } from '../types';

interface ProcedureFormProps {
  onSubmit: (procedure: ProcedureFormData & { dependencies?: string[] }) => void;
  onCancel: () => void;
  existingProcedures?: Procedure[];
}

export function ProcedureForm({ onSubmit, onCancel, existingProcedures = [] }: ProcedureFormProps) {
  const [formData, setFormData] = useState<ProcedureFormData & { dependencies?: string[] }>({
    name: '',
    totalSteps: 1,
    runNumber: 1,
    isComplete: false,
    description: '',
    dependencies: [],
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>, field: 'totalSteps' | 'runNumber') => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setFormData({ ...formData, [field]: value });
    }
  };

  const handleDependencyChange = (procedureId: string) => {
    const dependencies = formData.dependencies || [];
    const newDependencies = dependencies.includes(procedureId)
      ? dependencies.filter((id) => id !== procedureId)
      : [...dependencies, procedureId];
    setFormData({ ...formData, dependencies: newDependencies });
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">New Procedure</h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Procedure Name
          </label>
          <input
            type="text"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter procedure name"
            autoFocus
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Total Steps
            </label>
            <input
              type="number"
              required
              min="1"
              value={formData.totalSteps}
              onChange={(e) => handleNumberChange(e, 'totalSteps')}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Run Number
            </label>
            <input
              type="number"
              required
              min="1"
              value={formData.runNumber}
              onChange={(e) => handleNumberChange(e, 'runNumber')}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {existingProcedures.length > 0 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Dependencies
            </label>
            <div className="space-y-2 max-h-40 overflow-y-auto">
              {existingProcedures.map((proc) => (
                <label
                  key={proc.id}
                  className="flex items-center space-x-2 p-2 border border-gray-200 rounded hover:bg-gray-50"
                >
                  <input
                    type="checkbox"
                    checked={formData.dependencies?.includes(proc.id) || false}
                    onChange={() => handleDependencyChange(proc.id)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-900">{proc.name}</span>
                </label>
              ))}
            </div>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description (Optional)
          </label>
          <textarea
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
            placeholder="Enter procedure description"
          />
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Add Procedure
          </button>
        </div>
      </form>
    </div>
  );
}