import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableProcedureCard } from './SortableProcedureCard';
import type { Procedure } from '../types';

interface ProcedureListProps {
  procedures: Procedure[];
  onComplete: (id: string) => void;
  onIncrementStep: (id: string) => void;
  onReorder: (procedures: Procedure[]) => void;
  onUpdate: (procedure: Procedure) => void;
  onDelete: (id: string) => void;
  selectedProcedureId?: string | null;
  procedureRefs?: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
}

export function ProcedureList({
  procedures,
  onComplete,
  onIncrementStep,
  onReorder,
  onUpdate,
  onDelete,
  selectedProcedureId,
  procedureRefs,
}: ProcedureListProps) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = procedures.findIndex((p) => p.id === active.id);
      const newIndex = procedures.findIndex((p) => p.id === over.id);
      
      const newProcedures = arrayMove(procedures, oldIndex, newIndex).map(
        (proc, index) => ({
          ...proc,
          order: index,
        })
      );
      
      onReorder(newProcedures);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={procedures}
        strategy={verticalListSortingStrategy}
      >
        <div className="grid gap-6">
          {procedures.map((procedure) => (
            <div
              key={procedure.id}
              ref={(el) => {
                if (procedureRefs) {
                  procedureRefs.current[procedure.id] = el;
                }
              }}
            >
              <SortableProcedureCard
                procedure={procedure}
                onComplete={onComplete}
                onIncrementStep={onIncrementStep}
                onUpdate={onUpdate}
                onDelete={onDelete}
                existingProcedures={procedures.filter(p => p.id !== procedure.id)}
                isSelected={selectedProcedureId === procedure.id}
              />
            </div>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}