import React from 'react';
import { Sidebar } from './Sidebar';
import { TopNav } from './TopNav';

interface MainLayoutProps {
  children: React.ReactNode;
  activeSection: 'satellites' | 'harnesses';
  onSectionChange: (section: 'satellites' | 'harnesses') => void;
  currentUser: any;
  onLogout: () => void;
}

export function MainLayout({ 
  children, 
  activeSection,
  onSectionChange,
  currentUser,
  onLogout
}: MainLayoutProps) {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-50 flex">
      {/* Sidebar */}
      <Sidebar 
        activeSection={activeSection}
        onSectionChange={onSectionChange}
      />

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <TopNav 
          currentUser={currentUser}
          onLogout={onLogout}
        />
        <main className="flex-1 p-4">
          {children}
        </main>
      </div>
    </div>
  );
}