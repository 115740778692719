import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAHYuaIO0mEG7Ha4ZEUm8Mpe37_bimylRQ",
  authDomain: "spacecraft-config.firebaseapp.com",
  projectId: "spacecraft-config",
  storageBucket: "spacecraft-config.firebasestorage.app",
  messagingSenderId: "560249471263",
  appId: "1:560249471263:web:ad0b1e792abe38fab8302a"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);