import React from 'react';
import { Orbit, Rocket } from 'lucide-react';

interface TopNavProps {
  currentUser: any;
  onLogout: () => void;
}

export function TopNav({ currentUser, onLogout }: TopNavProps) {
  return (
    <header className="bg-gradient-to-r from-indigo-600 via-blue-600 to-indigo-700 text-white shadow-lg">
      <div className="container mx-auto">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex-1 flex items-center">
            <div className="flex items-center space-x-4">
              <div className="relative w-10 h-10">
                <Orbit 
                  className="text-white/80 animate-spin-slow absolute inset-0" 
                  size={40} 
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-5 h-5 flex items-center justify-center">
                    <Rocket className="text-white" size={16} />
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold tracking-wider bg-gradient-to-r from-white via-blue-100 to-white bg-clip-text text-transparent">
                  CONFIGONAUT
                </h1>
                <p className="text-xs text-blue-200 uppercase tracking-widest">Mission Control</p>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-6">
            <div className="text-right">
              <p className="text-sm text-blue-100">Mission Controller</p>
              <p className="text-xs text-blue-200">{currentUser.email}</p>
            </div>
            <button
              onClick={onLogout}
              className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors text-sm font-medium"
            >
              Abort Mission
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}