import React, { useState } from 'react';
import { CheckCircle2, Circle, Rocket, Timer, Edit2, X, Save, Trash2, RotateCcw, Share2 } from 'lucide-react';
import type { Procedure } from '../types';

interface ProcedureCardProps {
  procedure: Procedure;
  existingProcedures?: Procedure[];
  onComplete: (id: string) => void;
  onIncrementStep: (id: string) => void;
  onUpdate: (procedure: Procedure) => void;
  onDelete: (id: string) => void;
}

export function ProcedureCard({
  procedure,
  existingProcedures = [],
  onComplete,
  onIncrementStep,
  onUpdate,
  onDelete,
}: ProcedureCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [showDependencies, setShowDependencies] = useState(false);
  const [editedProcedure, setEditedProcedure] = useState(procedure);
  const progress = (procedure.currentStep / procedure.totalSteps) * 100;

  const handleSave = () => {
    onUpdate(editedProcedure);
    setIsEditing(false);
    setShowDependencies(false);
  };

  const handleCancel = () => {
    setEditedProcedure(procedure);
    setIsEditing(false);
    setShowDependencies(false);
  };

  const handleRollback = () => {
    if (editedProcedure.currentStep > 0) {
      const updatedProcedure = {
        ...editedProcedure,
        currentStep: editedProcedure.currentStep - 1,
        isComplete: false,
      };
      setEditedProcedure(updatedProcedure);
      onUpdate(updatedProcedure);
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this procedure?')) {
      onDelete(procedure.id);
    }
  };

  const handleStepChange = (newStep: number) => {
    const validStep = Math.max(0, Math.min(newStep, editedProcedure.totalSteps));
    const updatedProcedure = {
      ...editedProcedure,
      currentStep: validStep,
      isComplete: validStep === editedProcedure.totalSteps,
    };
    setEditedProcedure(updatedProcedure);
    onUpdate(updatedProcedure);
  };

  const handleDependencyChange = (depId: string) => {
    const dependencies = editedProcedure.dependencies || [];
    const newDependencies = dependencies.includes(depId)
      ? dependencies.filter(id => id !== depId)
      : [...dependencies, depId];
    setEditedProcedure({ ...editedProcedure, dependencies: newDependencies });
  };

  const availableProcedures = existingProcedures.filter(p => p.id !== procedure.id);

  if (isEditing) {
    return (
      <div className="space-y-4">
        <div className="flex flex-wrap justify-between items-center gap-2 mb-4">
          <input
            type="text"
            value={editedProcedure.name}
            onChange={(e) =>
              setEditedProcedure({ ...editedProcedure, name: e.target.value })
            }
            className="flex-1 min-w-0 text-xl font-bold text-gray-900 border-b border-gray-300 focus:border-blue-500 focus:outline-none"
          />
          <div className="flex flex-shrink-0 space-x-2">
            <button
              onClick={handleSave}
              className="p-2 text-green-500 hover:text-green-600 transition-colors"
              title="Save changes"
            >
              <Save size={20} />
            </button>
            <button
              onClick={handleCancel}
              className="p-2 text-red-500 hover:text-red-600 transition-colors"
              title="Cancel"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {showDependencies && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Dependencies
            </label>
            <div className="space-y-2 max-h-40 overflow-y-auto">
              {availableProcedures.map((proc) => (
                <label
                  key={proc.id}
                  className="flex items-center space-x-2 p-2 border border-gray-200 rounded hover:bg-gray-50"
                >
                  <input
                    type="checkbox"
                    checked={editedProcedure.dependencies?.includes(proc.id) || false}
                    onChange={() => handleDependencyChange(proc.id)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-900">{proc.name}</span>
                  {proc.isComplete && (
                    <CheckCircle2 className="text-green-500" size={16} />
                  )}
                </label>
              ))}
            </div>
          </div>
        )}

        <div className="grid gap-4 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Current Step
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="number"
                value={editedProcedure.currentStep}
                onChange={(e) => handleStepChange(parseInt(e.target.value))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                min="0"
                max={editedProcedure.totalSteps}
              />
              <button
                onClick={handleRollback}
                disabled={editedProcedure.currentStep === 0}
                className="p-2 text-blue-500 hover:text-blue-600 disabled:text-gray-300 transition-colors"
                title="Roll back one step"
              >
                <RotateCcw size={20} />
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Run Number
            </label>
            <input
              type="number"
              value={editedProcedure.runNumber}
              onChange={(e) =>
                setEditedProcedure({
                  ...editedProcedure,
                  runNumber: parseInt(e.target.value),
                })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              min="1"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Total Steps
          </label>
          <input
            type="number"
            value={editedProcedure.totalSteps}
            onChange={(e) =>
              setEditedProcedure({
                ...editedProcedure,
                totalSteps: parseInt(e.target.value),
              })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            min="1"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={editedProcedure.description || ''}
            onChange={(e) =>
              setEditedProcedure({
                ...editedProcedure,
                description: e.target.value,
              })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            rows={3}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-wrap justify-between items-start gap-4 mb-4">
        <div className="flex-1 min-w-0">
          <h3 className="text-xl font-bold text-gray-900 truncate">{procedure.name}</h3>
          <p className="text-sm text-gray-600 mt-1">Run #{procedure.runNumber}</p>
        </div>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={handleDelete}
            className="p-2 text-red-400 hover:text-red-500 transition-colors"
            title="Delete procedure"
          >
            <Trash2 size={20} />
          </button>
          <button
            onClick={() => setShowDependencies(!showDependencies)}
            className={`p-2 ${
              showDependencies ? 'text-blue-500' : 'text-gray-400'
            } hover:text-blue-600 transition-colors`}
            title="Manage dependencies"
          >
            <Share2 size={20} />
          </button>
          <button
            onClick={() => setIsEditing(true)}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
            title="Edit procedure"
          >
            <Edit2 size={20} />
          </button>
          <button
            onClick={() => onComplete(procedure.id)}
            className={`p-2 rounded-full transition-colors ${
              procedure.isComplete
                ? 'text-green-500 hover:text-green-600'
                : 'text-gray-400 hover:text-gray-500'
            }`}
            title={procedure.isComplete ? 'Mark as incomplete' : 'Mark as complete'}
          >
            {procedure.isComplete ? <CheckCircle2 size={24} /> : <Circle size={24} />}
          </button>
        </div>
      </div>

      {procedure.description && (
        <p className="text-gray-600 mb-4 text-sm">{procedure.description}</p>
      )}

      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium text-gray-700">
            Progress: {procedure.currentStep}/{procedure.totalSteps} steps
          </span>
          <span className="text-sm font-medium text-gray-700">
            {Math.round(progress)}%
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-between items-center gap-4">
        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <Timer size={16} />
          <span>Updated: {new Date(procedure.updatedAt).toLocaleDateString()}</span>
        </div>
        <button
          onClick={() => onIncrementStep(procedure.id)}
          disabled={procedure.currentStep >= procedure.totalSteps}
          className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          <Rocket size={16} />
          <span>Next Step</span>
        </button>
      </div>

      {procedure.dependencies && procedure.dependencies.length > 0 && (
        <div className="flex items-center space-x-1 mt-4">
          <Share2 className="text-blue-500" size={14} />
          <span className="text-xs text-gray-500">
            {procedure.dependencies.length} dependencies
          </span>
        </div>
      )}
    </>
  );
}