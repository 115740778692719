import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Assembly, AssemblyFormData, Procedure } from '../types';

interface ParentAssemblyFormProps {
  assemblies: Assembly[];
  procedures: Procedure[];
  assembly?: Assembly | null;
  onSubmit: (formData: AssemblyFormData, selectedAssemblies: string[], selectedProcedures: string[]) => void;
  onCancel: () => void;
}

export function ParentAssemblyForm({
  assemblies,
  procedures,
  assembly,
  onSubmit,
  onCancel,
}: ParentAssemblyFormProps) {
  const [formData, setFormData] = useState<AssemblyFormData>({
    name: assembly?.name || '',
    description: assembly?.description || '',
  });
  const [selectedAssemblies, setSelectedAssemblies] = useState<string[]>(
    assembly?.subAssemblies?.map(a => a.id) || []
  );
  const [selectedProcedures, setSelectedProcedures] = useState<string[]>(
    assembly?.procedures?.map(p => p.id) || []
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData, selectedAssemblies, selectedProcedures);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">
          {assembly ? 'Edit Parent Assembly' : 'Create Parent Assembly'}
        </h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Parent Assembly Name
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter parent assembly name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description (Optional)
          </label>
          <textarea
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            rows={3}
            placeholder="Enter parent assembly description"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Sub-Assemblies
          </label>
          <div className="grid gap-2 max-h-60 overflow-y-auto">
            {assemblies.map((a) => (
              <div
                key={a.id}
                className="flex items-center space-x-2 p-2 border border-gray-200 rounded"
              >
                <input
                  type="checkbox"
                  checked={selectedAssemblies.includes(a.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedAssemblies([...selectedAssemblies, a.id]);
                    } else {
                      setSelectedAssemblies(
                        selectedAssemblies.filter((id) => id !== a.id)
                      );
                    }
                  }}
                  className="h-4 w-4 text-blue-600"
                />
                <span className="text-sm text-gray-900">{a.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Procedures
          </label>
          <div className="grid gap-2 max-h-60 overflow-y-auto">
            {procedures.map((procedure) => (
              <div
                key={procedure.id}
                className="flex items-center space-x-2 p-2 border border-gray-200 rounded"
              >
                <input
                  type="checkbox"
                  checked={selectedProcedures.includes(procedure.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedProcedures([...selectedProcedures, procedure.id]);
                    } else {
                      setSelectedProcedures(
                        selectedProcedures.filter((id) => id !== procedure.id)
                      );
                    }
                  }}
                  className="h-4 w-4 text-blue-600"
                />
                <span className="text-sm text-gray-900">{procedure.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {assembly ? 'Update Parent Assembly' : 'Create Parent Assembly'}
          </button>
        </div>
      </form>
    </div>
  );
}