import React, { useState, useRef } from 'react';
import { Edit2, Layout, Network, Plus, Upload, Table2 } from 'lucide-react';
import { ProcedureForm } from './ProcedureForm';
import { ProcedureList } from './ProcedureList';
import { AssemblyCanvas } from './AssemblyCanvas';
import { StandaloneAssemblyTree } from './StandaloneAssemblyTree';
import { CSVImportButton } from './CSVImport/CSVImportButton';
import { ImportPreview } from './CSVImport/ImportPreview';
import { ImportSummary } from './CSVImport/ImportSummary';
import { AssemblyTable } from './AssemblyTableView/AssemblyTable';
import type { Satellite, Procedure, Assembly, CSVProcedure, ImportResult } from '../types';

interface SatelliteWorkspaceProps {
  satellite: Satellite;
  onUpdate: (satellite: Satellite) => void;
}

export function SatelliteWorkspace({ satellite, onUpdate }: SatelliteWorkspaceProps) {
  const [activeView, setActiveView] = useState<'procedures' | 'assemblies' | 'tree' | 'table'>('procedures');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssemblyId, setSelectedAssemblyId] = useState<string | null>(null);
  const [selectedProcedureId, setSelectedProcedureId] = useState<string | null>(null);
  const [showProcedureForm, setShowProcedureForm] = useState(false);
  const [previewData, setPreviewData] = useState<CSVProcedure[] | null>(null);
  const [importResult, setImportResult] = useState<ImportResult | null>(null);
  const assemblyRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const procedureRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const [editForm, setEditForm] = useState({
    name: satellite.name,
    description: satellite.description || ''
  });

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdate({
      ...satellite,
      name: editForm.name,
      description: editForm.description,
      updatedAt: new Date()
    });
    setIsEditing(false);
  };

  const handleAddProcedure = (formData: Omit<Procedure, 'id' | 'currentStep' | 'order' | 'createdAt' | 'updatedAt'>) => {
    const newProcedure: Procedure = {
      ...formData,
      id: crypto.randomUUID(),
      currentStep: 0,
      order: satellite.procedures.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    onUpdate({
      ...satellite,
      procedures: [...satellite.procedures, newProcedure],
      status: 'in-progress',
      updatedAt: new Date()
    });
    setShowProcedureForm(false);
  };

  const handleImportProcedures = (procedures: CSVProcedure[]) => {
    const successful: CSVProcedure[] = [];
    const failed: { name: string; error: string }[] = [];

    const newProcedures = procedures.map((proc) => {
      try {
        const newProc: Procedure = {
          id: crypto.randomUUID(),
          name: proc.name,
          description: proc.description,
          totalSteps: proc.steps,
          currentStep: 0,
          runNumber: 1,
          isComplete: false,
          order: satellite.procedures.length + successful.length,
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        successful.push(proc);
        return newProc;
      } catch (err) {
        failed.push({
          name: proc.name,
          error: err instanceof Error ? err.message : 'Unknown error'
        });
        return null;
      }
    }).filter((p): p is Procedure => p !== null);

    if (newProcedures.length > 0) {
      onUpdate({
        ...satellite,
        procedures: [...satellite.procedures, ...newProcedures],
        status: 'in-progress',
        updatedAt: new Date()
      });
    }

    setImportResult({
      successful,
      failed,
      total: procedures.length
    });
    setPreviewData(null);
  };

  const handleUpdateProcedure = (updatedProcedure: Procedure) => {
    const newProcedures = satellite.procedures.map((proc) =>
      proc.id === updatedProcedure.id
        ? { ...updatedProcedure, updatedAt: new Date() }
        : proc
    );

    const allComplete = newProcedures.every((p) => p.isComplete);
    const anyStarted = newProcedures.some((p) => p.currentStep > 0);
    const status = allComplete ? 'complete' : anyStarted ? 'in-progress' : 'planned';

    const newAssemblies = satellite.assemblies.map(assembly => ({
      ...assembly,
      procedures: assembly.procedures.map(proc => 
        proc.id === updatedProcedure.id ? updatedProcedure : proc
      )
    }));

    onUpdate({
      ...satellite,
      procedures: newProcedures,
      assemblies: newAssemblies,
      status,
      updatedAt: new Date()
    });
  };

  const handleComplete = (id: string) => {
    const newProcedures = satellite.procedures.map((proc) =>
      proc.id === id
        ? {
            ...proc,
            isComplete: !proc.isComplete,
            updatedAt: new Date(),
          }
        : proc
    );

    const allComplete = newProcedures.every((p) => p.isComplete);
    onUpdate({
      ...satellite,
      procedures: newProcedures,
      status: allComplete ? 'complete' : 'in-progress',
      updatedAt: new Date()
    });
  };

  const handleIncrementStep = (id: string) => {
    const newProcedures = satellite.procedures.map((proc) =>
      proc.id === id && proc.currentStep < proc.totalSteps
        ? {
            ...proc,
            currentStep: proc.currentStep + 1,
            isComplete: proc.currentStep + 1 === proc.totalSteps,
            updatedAt: new Date(),
          }
        : proc
    );

    const newAssemblies = satellite.assemblies.map(assembly => ({
      ...assembly,
      procedures: assembly.procedures.map(proc => 
        proc.id === id ? newProcedures.find(p => p.id === id)! : proc
      )
    }));

    onUpdate({
      ...satellite,
      procedures: newProcedures,
      assemblies: newAssemblies,
      status: 'in-progress',
      updatedAt: new Date()
    });
  };

  const handleDeleteProcedure = (id: string) => {
    const newProcedures = satellite.procedures.filter((proc) => proc.id !== id);
    const newAssemblies = satellite.assemblies.map((assembly) => ({
      ...assembly,
      procedures: assembly.procedures.filter((proc) => proc.id !== id),
    }));

    onUpdate({
      ...satellite,
      procedures: newProcedures,
      assemblies: newAssemblies,
      updatedAt: new Date()
    });
  };

  const handleReorder = (reorderedProcedures: Procedure[]) => {
    onUpdate({
      ...satellite,
      procedures: reorderedProcedures,
      updatedAt: new Date()
    });
  };

  const handleUpdateAssemblies = (newAssemblies: Assembly[]) => {
    onUpdate({
      ...satellite,
      assemblies: newAssemblies,
      updatedAt: new Date()
    });
  };

  const handleTableSave = (rows: Array<{
    assemblyName: string;
    childAssemblies: string[];
    procedures: string[];
  }>) => {
    // Create new procedures if they don't exist
    const newProcedures = new Map<string, Procedure>();
    rows.forEach(row => {
      row.procedures.forEach(procName => {
        if (!satellite.procedures.some(p => p.name === procName) && !newProcedures.has(procName)) {
          newProcedures.set(procName, {
            id: crypto.randomUUID(),
            name: procName,
            totalSteps: 1,
            currentStep: 0,
            runNumber: 1,
            isComplete: false,
            order: satellite.procedures.length + newProcedures.size,
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        }
      });
    });

    // Create or update assemblies
    const allProcedures = [...satellite.procedures, ...Array.from(newProcedures.values())];
    const updatedAssemblies = new Map<string, Assembly>();

    // First pass: Create or update basic assembly info
    rows.forEach(row => {
      const existingAssembly = satellite.assemblies.find(a => a.name === row.assemblyName);
      const procedures = row.procedures.map(procName => 
        allProcedures.find(p => p.name === procName)!
      ).filter(Boolean);

      if (existingAssembly) {
        updatedAssemblies.set(row.assemblyName, {
          ...existingAssembly,
          procedures,
          isParent: row.childAssemblies.length > 0,
          updatedAt: new Date(),
        });
      } else {
        updatedAssemblies.set(row.assemblyName, {
          id: crypto.randomUUID(),
          name: row.assemblyName,
          procedures,
          subAssemblies: [],
          isParent: row.childAssemblies.length > 0,
          order: satellite.assemblies.length + updatedAssemblies.size,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    });

    // Second pass: Update child assembly relationships
    rows.forEach(row => {
      const assembly = updatedAssemblies.get(row.assemblyName);
      if (assembly) {
        const childAssemblies = row.childAssemblies
          .map(childName => {
            const childAssembly = updatedAssemblies.get(childName);
            return childAssembly ? { id: childAssembly.id } : null;
          })
          .filter((child): child is { id: string } => child !== null);

        updatedAssemblies.set(row.assemblyName, {
          ...assembly,
          subAssemblies: childAssemblies,
          isParent: childAssemblies.length > 0,
        });
      }
    });

    // Update the satellite with new data
    onUpdate({
      ...satellite,
      procedures: allProcedures,
      assemblies: Array.from(updatedAssemblies.values()),
      updatedAt: new Date()
    });
  };

  const handleSelectAssembly = (assemblyId: string) => {
    setSelectedAssemblyId(assemblyId);
    setActiveView('assemblies');
    
    setTimeout(() => {
      const element = assemblyRefs.current[assemblyId];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const handleSelectProcedure = (procedureId: string) => {
    setSelectedProcedureId(procedureId);
    setActiveView('procedures');
    
    setTimeout(() => {
      const element = procedureRefs.current[procedureId];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const sortedProcedures = [...satellite.procedures].sort((a, b) => a.order - b.order);
  const completedCount = satellite.procedures.filter((p) => p.isComplete).length;
  const totalSteps = satellite.procedures.reduce((acc, p) => acc + p.totalSteps, 0);
  const completedSteps = satellite.procedures.reduce((acc, p) => acc + p.currentStep, 0);

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6">
        {isEditing ? (
          <form onSubmit={handleEditSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Assembly Name
              </label>
              <input
                type="text"
                value={editForm.name}
                onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={editForm.description}
                onChange={(e) => setEditForm({ ...editForm, description: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows={3}
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="flex flex-wrap justify-between items-start gap-4">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold text-gray-900 truncate">{satellite.name}</h2>
                {satellite.description && (
                  <p className="text-gray-600 mt-1 line-clamp-2">{satellite.description}</p>
                )}
              </div>
              <div className="flex flex-wrap gap-3">
                <button
                  onClick={() => setIsEditing(true)}
                  className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <Edit2 size={16} />
                  <span>Edit Details</span>
                </button>
                <div className="flex rounded-lg border border-gray-200 divide-x">
                  <button
                    onClick={() => setActiveView('procedures')}
                    className={`px-3 sm:px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'procedures'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors whitespace-nowrap`}
                  >
                    <Layout size={16} />
                    <span>Procedures</span>
                  </button>
                  <button
                    onClick={() => setActiveView('assemblies')}
                    className={`px-3 sm:px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'assemblies'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors whitespace-nowrap`}
                  >
                    <Network size={16} />
                    <span>Assemblies</span>
                  </button>
                  <button
                    onClick={() => setActiveView('tree')}
                    className={`px-3 sm:px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'tree'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors whitespace-nowrap`}
                  >
                    <Network size={16} />
                    <span>Tree</span>
                  </button>
                  <button
                    onClick={() => setActiveView('table')}
                    className={`px-3 sm:px-4 py-2 flex items-center space-x-2 ${
                      activeView === 'table'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    } transition-colors whitespace-nowrap`}
                  >
                    <Table2 size={16} />
                    <span>Table</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6">
              <div className="bg-blue-50 rounded-lg p-4">
                <div className="text-sm text-blue-600">Total Procedures</div>
                <div className="text-2xl font-bold text-blue-900">
                  {satellite.procedures.length}
                </div>
              </div>
              <div className="bg-green-50 rounded-lg p-4">
                <div className="text-sm text-green-600">Completed</div>
                <div className="text-2xl font-bold text-green-900">
                  {completedCount} / {satellite.procedures.length}
                </div>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <div className="text-sm text-purple-600">Steps Progress</div>
                <div className="text-2xl font-bold text-purple-900">
                  {completedSteps} / {totalSteps}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Action buttons for Procedures view */}
      {activeView === 'procedures' && (
        <div className="flex items-center justify-between bg-white rounded-lg shadow-lg p-4">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setShowProcedureForm(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus size={20} />
              <span>Add New Procedure</span>
            </button>
            <CSVImportButton
              onDataParsed={setPreviewData}
              onError={(error) => alert(error)}
            />
          </div>
        </div>
      )}

      {/* Main content section */}
      {activeView === 'assemblies' ? (
        <AssemblyCanvas
          procedures={satellite.procedures}
          assemblies={satellite.assemblies}
          onUpdateAssemblies={handleUpdateAssemblies}
          selectedAssemblyId={selectedAssemblyId}
          assemblyRefs={assemblyRefs}
        />
      ) : activeView === 'tree' ? (
        <StandaloneAssemblyTree 
          assemblies={satellite.assemblies}
          onSelectAssembly={handleSelectAssembly}
          onSelectProcedure={handleSelectProcedure}
          selectedAssemblyId={selectedAssemblyId}
          selectedProcedureId={selectedProcedureId}
        />
      ) : activeView === 'table' ? (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <AssemblyTable
            assemblies={satellite.assemblies}
            procedures={satellite.procedures}
            onSave={handleTableSave}
          />
        </div>
      ) : (
        <div className="space-y-6">
          {satellite.procedures.length > 0 ? (
            <ProcedureList
              procedures={sortedProcedures}
              onComplete={handleComplete}
              onIncrementStep={handleIncrementStep}
              onReorder={handleReorder}
              onUpdate={handleUpdateProcedure}
              onDelete={handleDeleteProcedure}
              selectedProcedureId={selectedProcedureId}
              procedureRefs={procedureRefs}
            />
          ) : (
            <div className="text-center py-12 text-gray-600">
              <p className="text-lg">No procedures added yet.</p>
              <p className="text-sm mt-2">
                Add your first procedure using the buttons above.
              </p>
            </div>
          )}
        </div>
      )}

      {/* Modals */}
      {showProcedureForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-lg">
            <ProcedureForm
              onSubmit={(formData) => {
                handleAddProcedure(formData);
                setShowProcedureForm(false);
              }}
              onCancel={() => setShowProcedureForm(false)}
              existingProcedures={sortedProcedures}
            />
          </div>
        </div>
      )}

      {previewData && (
        <ImportPreview
          procedures={previewData}
          existingProcedures={satellite.procedures.map(p => p.name)}
          onConfirm={handleImportProcedures}
          onCancel={() => setPreviewData(null)}
        />
      )}

      {importResult && (
        <ImportSummary
          result={importResult}
          onClose={() => setImportResult(null)}
        />
      )}
    </div>
  );
}