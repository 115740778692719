import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CollapseButtonProps {
  isCollapsed: boolean;
  onClick: () => void;
  direction?: 'left' | 'right';
}

export function CollapseButton({ isCollapsed, onClick, direction = 'left' }: CollapseButtonProps) {
  const Icon = direction === 'left' ? ChevronLeft : ChevronRight;
  
  return (
    <button
      onClick={onClick}
      className={`
        w-6 h-12 
        bg-white border border-gray-200 shadow-md 
        flex items-center justify-center 
        text-gray-500 hover:text-gray-700 hover:bg-gray-50
        transition-all duration-200
        rounded-r-lg
      `}
      title={isCollapsed ? 'Expand panel' : 'Collapse panel'}
    >
      <Icon size={16} />
    </button>
  );
}