import React from 'react';
import { CheckCircle2, AlertTriangle, X } from 'lucide-react';
import type { AssemblyCSVData, ValidationResult } from '../../types/csv';

interface AssemblyCSVPreviewProps {
  data: AssemblyCSVData[];
  validation: ValidationResult;
  onConfirm: () => void;
  onCancel: () => void;
}

export function AssemblyCSVPreview({ 
  data, 
  validation, 
  onConfirm, 
  onCancel 
}: AssemblyCSVPreviewProps) {
  const hasWarnings = validation.warnings.length > 0;
  const hasErrors = validation.errors.length > 0;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[80vh] flex flex-col">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-bold text-gray-900">
            Preview CSV Import ({data.length} assemblies)
          </h2>
          <button
            onClick={onCancel}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {(hasWarnings || hasErrors) && (
          <div className="px-6 py-4 bg-yellow-50 border-b border-yellow-100">
            <div className="flex items-center space-x-2 text-yellow-800">
              <AlertTriangle size={20} />
              <span>
                Found {validation.warnings.length} warning(s) and {validation.errors.length} error(s)
              </span>
            </div>
            {validation.warnings.map((warning, i) => (
              <p key={i} className="mt-2 text-sm text-yellow-700">{warning}</p>
            ))}
            {validation.errors.map((error, i) => (
              <p key={i} className="mt-2 text-sm text-red-600">{error}</p>
            ))}
          </div>
        )}

        <div className="flex-1 overflow-auto p-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Assembly Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Child Assemblies
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Procedures
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {row.assemblyName}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {row.childAssemblies.join(', ')}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {row.procedures.join(', ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="p-6 border-t bg-gray-50 flex justify-end space-x-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={hasErrors}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <CheckCircle2 size={20} />
            <span>Import Assemblies</span>
          </button>
        </div>
      </div>
    </div>
  );
}