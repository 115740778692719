import { parseAssemblyCSV } from './assemblyCSVParser';
import type { AssemblyCSVData, ValidationResult } from '../../types/csv';

export async function validateAssemblyCSV(file: File): Promise<void> {
  const text = await file.text();
  const firstLine = text.split('\n')[0].trim();
  const expectedHeaders = ['Assembly Name', 'Child Assemblies', 'Procedures'];
  
  // Validate headers
  const headers = firstLine.split(',').map(h => h.trim());
  const missingHeaders = expectedHeaders.filter(
    expected => !headers.some(h => h.toLowerCase() === expected.toLowerCase())
  );

  if (missingHeaders.length > 0) {
    throw new Error(
      `Missing required columns: ${missingHeaders.join(', ')}. CSV must contain exactly these columns: ${expectedHeaders.join(', ')}`
    );
  }

  if (headers.length > expectedHeaders.length) {
    throw new Error(
      `CSV contains extra columns. Only these columns are allowed: ${expectedHeaders.join(', ')}`
    );
  }
}

export function validateAssemblyData(
  data: AssemblyCSVData[], 
  existingAssemblies: string[]
): ValidationResult {
  const errors: string[] = [];
  const warnings: string[] = [];
  const seenNames = new Set<string>();

  data.forEach((row, index) => {
    const rowNum = index + 1;

    // Check for empty assembly name
    if (!row.assemblyName) {
      errors.push(`Row ${rowNum}: Assembly Name is required`);
    }

    // Check for duplicate names in CSV
    if (seenNames.has(row.assemblyName)) {
      errors.push(`Row ${rowNum}: Duplicate Assembly Name "${row.assemblyName}"`);
    }
    seenNames.add(row.assemblyName);

    // Check for existing assemblies
    if (existingAssemblies.includes(row.assemblyName)) {
      warnings.push(`Row ${rowNum}: Assembly "${row.assemblyName}" already exists and will be updated`);
    }

    // Validate child assembly references
    row.childAssemblies.forEach(childName => {
      if (!seenNames.has(childName) && !existingAssemblies.includes(childName)) {
        errors.push(`Row ${rowNum}: Child Assembly "${childName}" not found`);
      }
    });

    // Check for circular references
    const visited = new Set<string>();
    const checkCircular = (assemblyName: string): boolean => {
      if (visited.has(assemblyName)) {
        errors.push(`Row ${rowNum}: Circular reference detected involving "${assemblyName}"`);
        return true;
      }
      visited.add(assemblyName);
      const children = data.find(d => d.assemblyName === assemblyName)?.childAssemblies || [];
      return children.some(child => checkCircular(child));
    };
    checkCircular(row.assemblyName);
  });

  return { errors, warnings };
}