import React from 'react';
import { FileDown } from 'lucide-react';
import { Assembly } from '../types';
import { generateAssemblyExport } from '../utils/assemblyExport';

interface ExportButtonProps {
  assemblies: Assembly[];
}

export function ExportButton({ assemblies }: ExportButtonProps) {
  const handleExport = () => {
    const content = generateAssemblyExport(assemblies);
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `assembly-tree-${new Date().toISOString().split('T')[0]}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleExport}
      className="flex items-center space-x-2 px-3 py-2 bg-white text-gray-600 hover:text-gray-900 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors"
      title="Export assembly tree"
    >
      <FileDown size={18} />
      <span>Export Tree</span>
    </button>
  );
}