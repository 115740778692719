import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableSatelliteItem } from './SortableSatelliteItem';
import { Plus } from 'lucide-react';
import type { Satellite } from '../types';

interface SortableSatelliteListProps {
  satellites: Satellite[];
  selectedSatelliteId: string | null;
  onSelect: (id: string) => void;
  onAdd: (name: string, description?: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  onReorder: (satellites: Satellite[]) => void;
}

export function SortableSatelliteList({
  satellites,
  selectedSatelliteId,
  onSelect,
  onAdd,
  onDelete,
  onDuplicate,
  onReorder,
}: SortableSatelliteListProps) {
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(name.trim(), description.trim() || undefined);
    setName('');
    setDescription('');
    setShowForm(false);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = satellites.findIndex((s) => s.id === active.id);
      const newIndex = satellites.findIndex((s) => s.id === over.id);
      
      const newSatellites = arrayMove(satellites, oldIndex, newIndex).map(
        (satellite, index) => ({
          ...satellite,
          order: index,
        })
      );
      
      onReorder(newSatellites);
    }
  };

  // Sort satellites by order
  const sortedSatellites = [...satellites].sort((a, b) => a.order - b.order);

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-900">Assemblies</h2>
        <button
          onClick={() => setShowForm(true)}
          className="p-1.5 text-blue-600 hover:text-blue-700 transition-colors"
        >
          <Plus size={20} />
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="mb-4 space-y-3">
          <div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Assembly name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description (optional)"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              rows={3}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-3 py-1.5 text-gray-600 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Add Assembly
            </button>
          </div>
        </form>
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={sortedSatellites}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-2">
            {sortedSatellites.map((satellite) => (
              <SortableSatelliteItem
                key={satellite.id}
                satellite={satellite}
                isSelected={selectedSatelliteId === satellite.id}
                onSelect={onSelect}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {satellites.length === 0 && !showForm && (
        <p className="text-center text-gray-500 text-sm py-4">
          No assemblies added yet
        </p>
      )}
    </div>
  );
}