import React, { useState } from 'react';
import { Layers, ChevronRight, ChevronDown, Rocket } from 'lucide-react';
import { ProcedureNode } from './ProcedureNode';
import type { Assembly } from '../../types';

interface AssemblyNodeProps {
  assembly: Assembly;
  assemblies: Assembly[];
  level: number;
  onSelectAssembly?: (assemblyId: string) => void;
  onSelectProcedure?: (procedureId: string) => void;
  isSelected?: boolean;
  selectedProcedureId?: string | null;
  isRoot?: boolean;
  isHorizontal?: boolean;
}

const getLevelColors = (level: number) => {
  const colors = [
    'from-blue-50 to-indigo-50 hover:from-blue-100 hover:to-indigo-100 border-blue-200',
    'from-purple-50 to-fuchsia-50 hover:from-purple-100 hover:to-fuchsia-100 border-purple-200',
    'from-cyan-50 to-sky-50 hover:from-cyan-100 hover:to-sky-100 border-cyan-200',
    'from-emerald-50 to-teal-50 hover:from-emerald-100 hover:to-teal-100 border-emerald-200',
    'from-amber-50 to-orange-50 hover:from-amber-100 hover:to-orange-100 border-amber-200'
  ];
  return colors[level % colors.length];
};

export function AssemblyNode({ 
  assembly, 
  assemblies, 
  level, 
  onSelectAssembly, 
  onSelectProcedure,
  isSelected,
  selectedProcedureId,
  isRoot = false,
  isHorizontal = false
}: AssemblyNodeProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const progress = calculateProgress(assembly);
  const progressPercentage = progress.total > 0
    ? Math.round((progress.completed / progress.total) * 100)
    : 0;

  const childAssemblies = assembly.subAssemblies
    ?.map(sub => assemblies.find(a => a.id === sub.id))
    .filter((a): a is Assembly => a !== undefined);

  return (
    <div className={`${isHorizontal ? 'inline-flex flex-col mr-6' : 'space-y-3'}`}>
      <div 
        className={`
          rounded-lg p-4 transition-all cursor-pointer
          bg-gradient-to-br ${getLevelColors(level)}
          border-2 ${isSelected ? 'ring-2 ring-blue-500' : ''}
          ${level === 0 ? 'w-full' : isHorizontal ? 'w-80' : 'ml-6'}
        `}
        onClick={() => onSelectAssembly?.(assembly.id)}
      >
        <div className="flex items-center space-x-2 mb-2">
          <div className="flex items-center space-x-2">
            {isRoot ? (
              <Rocket className="text-blue-600 flex-shrink-0" size={20} />
            ) : assembly.isParent ? (
              <Layers className="text-blue-600 flex-shrink-0" size={20} />
            ) : (
              <ChevronRight className="text-blue-500 flex-shrink-0" size={20} />
            )}
            <h3 className="font-medium text-gray-900">{assembly.name}</h3>
          </div>
          {(assembly.procedures.length > 0 || (childAssemblies && childAssemblies.length > 0)) && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className="p-1 rounded-full hover:bg-white/50 transition-colors"
            >
              {isExpanded ? (
                <ChevronDown className="text-gray-500" size={16} />
              ) : (
                <ChevronRight className="text-gray-500" size={16} />
              )}
            </button>
          )}
        </div>

        {assembly.description && (
          <p className="text-sm text-gray-600 mt-0.5 mb-2">{assembly.description}</p>
        )}

        <div className="space-y-2">
          <div className="flex justify-between text-sm text-gray-600">
            <span>Level {level}</span>
            <span>{progress.completed}/{progress.total} complete</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-blue-600 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className={isHorizontal ? 'ml-4' : 'space-y-3 pl-6 border-l-2 border-gray-200'}>
          {assembly.procedures.map(procedure => (
            <ProcedureNode
              key={procedure.id}
              procedure={procedure}
              level={level}
              onSelect={onSelectProcedure}
              isSelected={selectedProcedureId === procedure.id}
              isHorizontal={isHorizontal}
            />
          ))}
          
          {childAssemblies && childAssemblies.length > 0 && (
            <div className={isHorizontal ? 'flex space-x-6' : 'space-y-3'}>
              {childAssemblies.map(child => (
                <AssemblyNode
                  key={child.id}
                  assembly={child}
                  assemblies={assemblies}
                  level={level + 1}
                  onSelectAssembly={onSelectAssembly}
                  onSelectProcedure={onSelectProcedure}
                  isSelected={isSelected}
                  selectedProcedureId={selectedProcedureId}
                  isHorizontal={isHorizontal}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function calculateProgress(assembly: Assembly) {
  const completed = assembly.procedures.filter(p => p.isComplete).length;
  const total = assembly.procedures.length;
  return { completed, total };
}