import type { CSVProcedure } from '../types';

interface ValidationResult {
  procedures: CSVProcedure[];
  errors: string[];
}

export function validateProcedures(procedures: CSVProcedure[]): ValidationResult {
  const errors: string[] = [];
  const validProcedures: CSVProcedure[] = [];

  procedures.forEach((proc, index) => {
    const rowNum = index + 2; // Account for header row and 0-based index
    const rowErrors: string[] = [];

    // Required fields
    if (!proc.name) {
      rowErrors.push('Procedure Name is required');
    }
    if (!proc.steps || isNaN(proc.steps) || proc.steps < 1) {
      rowErrors.push('Steps must be a positive number');
    }
    if (!proc.category) {
      rowErrors.push('Category is required');
    }
    if (!proc.duration) {
      rowErrors.push('Duration is required');
    }
    if (!proc.requiredResources || proc.requiredResources.length === 0) {
      rowErrors.push('Required Resources is required');
    }

    // Validate risk level if provided
    if (proc.riskLevel && !['low', 'medium', 'high'].includes(proc.riskLevel.toLowerCase())) {
      rowErrors.push('Risk Level must be low, medium, or high');
    }

    if (rowErrors.length > 0) {
      errors.push(`Row ${rowNum} (${proc.name || 'Unnamed Procedure'}): ${rowErrors.join(', ')}`);
    } else {
      validProcedures.push(proc);
    }
  });

  return {
    procedures: validProcedures,
    errors,
  };
}