import React, { useState } from 'react';
import { Plus, Trash2, Copy, Layers, ChevronRight, Edit2, Cable } from 'lucide-react';
import type { Harness, ElectricalBox, HarnessComponent, Port, PortConnection } from '../types';
import { ElectricalBoxForm } from './ElectricalBoxForm';
import { HarnessComponentForm } from './HarnessComponentForm';
import { PortForm } from './PortForm';
import { PortConnectionForm } from './PortConnectionForm';
import { ConnectionEditor } from './ConnectionEditor';

interface HarnessConfigProps {
  harness: Harness;
  onUpdate: (harness: Harness) => void;
  boxRefs?: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
}

export function HarnessConfig({ harness, onUpdate, boxRefs }: HarnessConfigProps) {
  const [showElectricalBoxForm, setShowElectricalBoxForm] = useState(false);
  const [showHarnessForm, setShowHarnessForm] = useState(false);
  const [selectedElectricalBox, setSelectedElectricalBox] = useState<ElectricalBox | null>(null);
  const [editingBox, setEditingBox] = useState<ElectricalBox | null>(null);
  const [editingHarness, setEditingHarness] = useState<HarnessComponent | null>(null);
  const [showPortForm, setShowPortForm] = useState<{ boxId: string } | null>(null);
  const [editingPort, setEditingPort] = useState<{ boxId: string; port: Port } | null>(null);
  const [showConnectionForm, setShowConnectionForm] = useState<{ boxId: string; portId: string } | null>(null);
  const [editingConnection, setEditingConnection] = useState<PortConnection | null>(null);

  // Ensure arrays exist
  const electricalBoxes = harness.electricalBoxes || [];
  const connections = harness.connections || [];
  const harnesses = harness.harnesses || [];

  const handleAddHarness = (name: string, description?: string) => {
    const newHarness: HarnessComponent = {
      id: crypto.randomUUID(),
      name,
      description,
      order: harnesses.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    onUpdate({
      ...harness,
      harnesses: [...harnesses, newHarness],
      updatedAt: new Date(),
    });
    setShowHarnessForm(false);
  };

  const handleUpdateHarness = (harnessId: string, name: string, description?: string) => {
    onUpdate({
      ...harness,
      harnesses: harnesses.map(h =>
        h.id === harnessId
          ? { ...h, name, description, updatedAt: new Date() }
          : h
      ),
      updatedAt: new Date(),
    });
    setEditingHarness(null);
  };

  const handleDeleteHarness = (harnessId: string) => {
    if (!window.confirm('Are you sure you want to delete this harness?')) return;

    onUpdate({
      ...harness,
      harnesses: harnesses.filter(h => h.id !== harnessId),
      connections: connections.filter(conn => conn.harnessId !== harnessId),
      updatedAt: new Date(),
    });
  };

  const handleAddElectricalBox = (name: string, description?: string) => {
    const newBox: ElectricalBox = {
      id: crypto.randomUUID(),
      name,
      description,
      harnesses: [],
      ports: [],
      order: electricalBoxes.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    onUpdate({
      ...harness,
      electricalBoxes: [...electricalBoxes, newBox],
      updatedAt: new Date(),
    });
    setShowElectricalBoxForm(false);
  };

  const handleUpdateElectricalBox = (boxId: string, name: string, description?: string) => {
    onUpdate({
      ...harness,
      electricalBoxes: electricalBoxes.map(box =>
        box.id === boxId
          ? { ...box, name, description, updatedAt: new Date() }
          : box
      ),
      updatedAt: new Date(),
    });
    setEditingBox(null);
  };

  const handleDeleteElectricalBox = (boxId: string) => {
    if (!window.confirm('Are you sure you want to delete this electrical box?')) return;

    onUpdate({
      ...harness,
      electricalBoxes: electricalBoxes.filter(box => box.id !== boxId),
      connections: connections.filter(conn =>
        conn.sourceBoxId !== boxId && conn.targetBoxId !== boxId
      ),
      updatedAt: new Date(),
    });
  };

  const handleAddPort = (boxId: string, name: string, type: Port['type'], position: Port['position'], description?: string) => {
    const box = electricalBoxes.find(b => b.id === boxId);
    if (!box) return;

    const newPort: Port = {
      id: crypto.randomUUID(),
      name,
      type,
      position,
      description,
      order: box.ports.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    onUpdate({
      ...harness,
      electricalBoxes: electricalBoxes.map(b =>
        b.id === boxId
          ? { ...b, ports: [...b.ports, newPort], updatedAt: new Date() }
          : b
      ),
      updatedAt: new Date(),
    });
    setShowPortForm(null);
  };

  const handleUpdatePort = (boxId: string, portId: string, name: string, type: Port['type'], position: Port['position'], description?: string) => {
    onUpdate({
      ...harness,
      electricalBoxes: electricalBoxes.map(box =>
        box.id === boxId
          ? {
              ...box,
              ports: box.ports.map(port =>
                port.id === portId
                  ? { ...port, name, type, position, description, updatedAt: new Date() }
                  : port
              ),
              updatedAt: new Date(),
            }
          : box
      ),
      updatedAt: new Date(),
    });
    setEditingPort(null);
  };

  const handleDeletePort = (boxId: string, portId: string) => {
    if (!window.confirm('Are you sure you want to delete this port?')) return;

    onUpdate({
      ...harness,
      electricalBoxes: electricalBoxes.map(box =>
        box.id === boxId
          ? {
              ...box,
              ports: box.ports.filter(p => p.id !== portId),
              updatedAt: new Date(),
            }
          : box
      ),
      connections: connections.filter(conn =>
        conn.sourcePortId !== portId && conn.targetPortId !== portId
      ),
      updatedAt: new Date(),
    });
  };

  const handleAddConnections = (sourceBoxId: string, sourcePortId: string, targetConnections: Array<{ targetBoxId: string; targetPortId: string }>, harnessId: string) => {
    const newConnections = targetConnections.map(target => ({
      id: crypto.randomUUID(),
      sourceBoxId,
      sourcePortId,
      targetBoxId: target.targetBoxId,
      targetPortId: target.targetPortId,
      harnessId,
      createdAt: new Date(),
      updatedAt: new Date(),
    }));

    onUpdate({
      ...harness,
      connections: [...connections, ...newConnections],
      updatedAt: new Date(),
    });
    setShowConnectionForm(null);
  };

  const handleUpdateConnection = (updatedConnection: PortConnection) => {
    onUpdate({
      ...harness,
      connections: connections.map(conn =>
        conn.id === updatedConnection.id ? updatedConnection : conn
      ),
      updatedAt: new Date(),
    });
    setEditingConnection(null);
  };

  const handleDeleteConnection = (connectionId: string) => {
    if (!window.confirm('Are you sure you want to delete this connection?')) return;

    onUpdate({
      ...harness,
      connections: connections.filter(conn => conn.id !== connectionId),
      updatedAt: new Date(),
    });
  };

  return (
    <div className="space-y-6">
      {/* Harnesses Section */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Harnesses</h3>
          <button
            onClick={() => setShowHarnessForm(true)}
            className="p-1.5 text-blue-600 hover:text-blue-700 transition-colors"
          >
            <Plus size={20} />
          </button>
        </div>

        <div className="space-y-2">
          {harnesses.map(h => (
            <div
              key={h.id}
              className="flex justify-between items-center p-3 bg-gray-50 rounded-lg border border-gray-200"
            >
              <div>
                <h4 className="font-medium text-gray-900">{h.name}</h4>
                {h.description && (
                  <p className="text-sm text-gray-600">{h.description}</p>
                )}
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setEditingHarness(h)}
                  className="p-1.5 text-gray-400 hover:text-blue-500 transition-colors"
                  title="Edit harness"
                >
                  <Edit2 size={16} />
                </button>
                <button
                  onClick={() => handleDeleteHarness(h.id)}
                  className="p-1.5 text-red-400 hover:text-red-500 transition-colors"
                  title="Delete harness"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Electrical Boxes Section */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Electrical Boxes</h3>
          {!showElectricalBoxForm && !editingBox && (
            <button
              onClick={() => setShowElectricalBoxForm(true)}
              className="p-1.5 text-blue-600 hover:text-blue-700 transition-colors"
            >
              <Plus size={20} />
            </button>
          )}
        </div>

        <div className="space-y-6">
          {electricalBoxes.map(box => (
            <div
              key={box.id}
              ref={(el) => {
                if (boxRefs) {
                  boxRefs.current[box.id] = el;
                }
              }}
              className="p-4 bg-gray-50 rounded-lg border border-gray-200"
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h4 className="font-medium text-gray-900">{box.name}</h4>
                  {box.description && (
                    <p className="text-sm text-gray-600">{box.description}</p>
                  )}
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setEditingBox(box)}
                    className="p-1.5 text-gray-400 hover:text-blue-500 transition-colors"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => handleDeleteElectricalBox(box.id)}
                    className="p-1.5 text-gray-400 hover:text-red-500 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>

              {/* Ports Section */}
              <div className="mb-4">
                <div className="flex justify-between items-center mb-2">
                  <h5 className="text-sm font-medium text-gray-700">Ports</h5>
                  <button
                    onClick={() => setShowPortForm({ boxId: box.id })}
                    className="p-1 text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    <Plus size={16} />
                  </button>
                </div>

                <div className="grid gap-2">
                  {box.ports.map(port => (
                    <div
                      key={port.id}
                      className="flex justify-between items-center p-2 bg-white rounded border border-gray-200"
                    >
                      <div>
                        <div className="flex items-center space-x-2">
                          <span className="font-medium text-gray-900">{port.name}</span>
                          <span className="text-xs text-gray-500">({port.type})</span>
                        </div>
                        {port.description && (
                          <p className="text-xs text-gray-600">{port.description}</p>
                        )}
                        
                        {/* Port Connections */}
                        {connections.filter(conn => 
                          conn.sourcePortId === port.id || conn.targetPortId === port.id
                        ).map(connection => {
                          const isSource = connection.sourcePortId === port.id;
                          const otherBoxId = isSource ? connection.targetBoxId : connection.sourceBoxId;
                          const otherPortId = isSource ? connection.targetPortId : connection.sourcePortId;
                          const otherBox = electricalBoxes.find(b => b.id === otherBoxId);
                          const otherPort = otherBox?.ports.find(p => p.id === otherPortId);
                          const connectedHarness = harnesses.find(h => h.id === connection.harnessId);

                          if (!otherBox || !otherPort || !connectedHarness) return null;

                          return (
                            <div key={connection.id} className="mt-1 flex items-center justify-between text-xs bg-gray-50 rounded px-2 py-1">
                              <div className="flex items-center space-x-1">
                                <span className="text-gray-600">Connected to:</span>
                                <span className="font-medium">{otherBox.name}</span>
                                <span>→</span>
                                <span>{otherPort.name}</span>
                                <span className="text-gray-500">
                                  ({connectedHarness.name})
                                </span>
                              </div>
                              <div className="flex space-x-1">
                                <button
                                  onClick={() => setEditingConnection(connection)}
                                  className="p-1 text-gray-400 hover:text-blue-500"
                                  title="Edit connection"
                                >
                                  <Edit2 size={10} />
                                </button>
                                <button
                                  onClick={() => handleDeleteConnection(connection.id)}
                                  className="p-1 text-gray-400 hover:text-red-500"
                                  title="Remove connection"
                                >
                                  <Trash2 size={10} />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => setShowConnectionForm({ boxId: box.id, portId: port.id })}
                          className="p-1 text-gray-400 hover:text-blue-500 transition-colors"
                          title="Add connection"
                        >
                          <Cable size={14} />
                        </button>
                        <button
                          onClick={() => setEditingPort({ boxId: box.id, port })}
                          className="p-1 text-gray-400 hover:text-blue-500 transition-colors"
                        >
                          <Edit2 size={14} />
                        </button>
                        <button
                          onClick={() => handleDeletePort(box.id, port.id)}
                          className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                        >
                          <Trash2 size={14} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Forms */}
      {(showHarnessForm || editingHarness) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="w-full max-w-lg">
            <HarnessComponentForm
              harness={editingHarness}
              onSubmit={editingHarness 
                ? (name, description) => handleUpdateHarness(editingHarness.id, name, description)
                : handleAddHarness
              }
              onCancel={() => {
                setShowHarnessForm(false);
                setEditingHarness(null);
              }}
            />
          </div>
        </div>
      )}

      {(showElectricalBoxForm || editingBox) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="w-full max-w-lg">
            <ElectricalBoxForm
              box={editingBox}
              onSubmit={editingBox 
                ? (name, description) => handleUpdateElectricalBox(editingBox.id, name, description)
                : handleAddElectricalBox
              }
              onCancel={() => {
                setShowElectricalBoxForm(false);
                setEditingBox(null);
              }}
            />
          </div>
        </div>
      )}

      {(showPortForm || editingPort) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="w-full max-w-lg">
            <PortForm
              port={editingPort?.port}
              onSubmit={(name, type, position, description) =>
                editingPort
                  ? handleUpdatePort(editingPort.boxId, editingPort.port.id, name, type, position, description)
                  : handleAddPort(showPortForm!.boxId, name, type, position, description)
              }
              onCancel={() => {
                setShowPortForm(null);
                setEditingPort(null);
              }}
            />
          </div>
        </div>
      )}

      {showConnectionForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="w-full max-w-lg">
            <PortConnectionForm
              harness={harness}
              sourceBoxId={showConnectionForm.boxId}
              sourcePortId={showConnectionForm.portId}
              onSubmit={(targetConnections, harnessId) =>
                handleAddConnections(
                  showConnectionForm.boxId,
                  showConnectionForm.portId,
                  targetConnections,
                  harnessId
                )
              }
              onCancel={() => setShowConnectionForm(null)}
            />
          </div>
        </div>
      )}

      {editingConnection && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
          <div className="w-full max-w-lg">
            <ConnectionEditor
              harness={harness}
              connection={editingConnection}
              onUpdate={handleUpdateConnection}
              onCancel={() => setEditingConnection(null)}
            />
          </div>
        </div>
      )}
    </div>
  );
}