import React from 'react';
import { Palette, Monitor } from 'lucide-react';

export function AppearanceSettings() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center space-x-2">
        <Palette className="text-indigo-600" size={20} />
        <span>Appearance</span>
      </h3>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Theme</label>
          <select className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md">
            <option>System Default</option>
            <option>Light</option>
            <option>Dark</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Display Density</label>
          <div className="mt-2 space-y-2">
            <label className="inline-flex items-center">
              <input type="radio" name="density" className="form-radio text-indigo-600" defaultChecked />
              <span className="ml-2">Comfortable</span>
            </label>
            <label className="inline-flex items-center">
              <input type="radio" name="density" className="form-radio text-indigo-600" />
              <span className="ml-2">Compact</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}