import React from 'react';
import { Orbit, Rocket, Cable } from 'lucide-react';

interface EmptyStateProps {
  type: 'assembly' | 'harness';
}

export function EmptyState({ type }: EmptyStateProps) {
  const Icon = type === 'assembly' ? Rocket : Cable;
  const title = type === 'assembly' ? 'Select an Assembly' : 'Select a Harness';
  const description = type === 'assembly'
    ? 'Choose an assembly from the list or create a new one to begin configuration.'
    : 'Choose a harness from the list or create a new one to begin configuration.';

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 text-center">
      <div className="flex justify-center mb-4">
        <div className="relative">
          <Orbit className="w-16 h-16 text-indigo-400 animate-spin-slow" />
          <Icon className="w-6 h-6 text-indigo-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
      <h2 className="text-xl font-medium text-gray-900 mb-2">
        {title}
      </h2>
      <p className="text-gray-600">
        {description}
      </p>
    </div>
  );
}