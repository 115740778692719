import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical } from 'lucide-react';
import { ProcedureCard } from './ProcedureCard';
import type { Procedure } from '../types';

interface SortableProcedureCardProps {
  procedure: Procedure;
  onComplete: (id: string) => void;
  onIncrementStep: (id: string) => void;
  onUpdate: (procedure: Procedure) => void;
  onDelete: (id: string) => void;
  existingProcedures: Procedure[];
  isSelected?: boolean;
}

export function SortableProcedureCard({
  procedure,
  onComplete,
  onIncrementStep,
  onUpdate,
  onDelete,
  existingProcedures,
  isSelected = false,
}: SortableProcedureCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: procedure.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className={`
        group relative bg-white rounded-lg shadow-lg p-6 transition-all hover:shadow-xl
        ${isSelected ? 'ring-2 ring-blue-500 shadow-lg' : ''}
      `}>
        <div
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-grab active:cursor-grabbing"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="text-gray-400" />
        </div>
        <ProcedureCard
          procedure={procedure}
          onComplete={onComplete}
          onIncrementStep={onIncrementStep}
          onUpdate={onUpdate}
          onDelete={onDelete}
          existingProcedures={existingProcedures}
        />
      </div>
    </div>
  );
}