import React from 'react';
import { Home, Rocket, Cable, BarChart2, Settings } from 'lucide-react';

interface SidebarProps {
  activeSection: 'dashboard' | 'satellites' | 'harnesses' | 'quality' | 'settings';
  onSectionChange: (section: 'dashboard' | 'satellites' | 'harnesses' | 'quality' | 'settings') => void;
}

export function Sidebar({ activeSection, onSectionChange }: SidebarProps) {
  return (
    <div className="w-16 bg-gradient-to-b from-indigo-900 to-indigo-800 shadow-lg flex flex-col items-center py-4">
      <div className="space-y-4">
        <button
          onClick={() => onSectionChange('settings')}
          className={`p-3 rounded-lg transition-colors ${
            activeSection === 'settings'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Settings"
        >
          <Settings size={24} />
        </button>
        <button
          onClick={() => onSectionChange('dashboard')}
          className={`p-3 rounded-lg transition-colors ${
            activeSection === 'dashboard'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Dashboard"
        >
          <Home size={24} />
        </button>
        <button
          onClick={() => onSectionChange('satellites')}
          className={`p-3 rounded-lg transition-colors ${
            activeSection === 'satellites'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Assemblies"
        >
          <Rocket size={24} />
        </button>
        <button
          onClick={() => onSectionChange('harnesses')}
          className={`p-3 rounded-lg transition-colors ${
            activeSection === 'harnesses'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Harnesses"
        >
          <Cable size={24} />
        </button>
        <button
          onClick={() => onSectionChange('quality')}
          className={`p-3 rounded-lg transition-colors ${
            activeSection === 'quality'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Quality"
        >
          <BarChart2 size={24} />
        </button>
      </div>
    </div>
  );
}