import React, { useState } from 'react';
import { AlertCircle, CheckCircle, X } from 'lucide-react';
import type { CSVProcedure } from '../../types';

interface ImportPreviewProps {
  procedures: CSVProcedure[];
  existingProcedures: string[];
  onConfirm: (procedures: CSVProcedure[]) => void;
  onCancel: () => void;
}

export function ImportPreview({
  procedures,
  existingProcedures,
  onConfirm,
  onCancel,
}: ImportPreviewProps) {
  const [selectedProcedures, setSelectedProcedures] = useState<Set<string>>(
    new Set(procedures.map(p => p.name))
  );

  const duplicates = procedures.filter(p => existingProcedures.includes(p.name));
  const hasDuplicates = duplicates.length > 0;

  const toggleProcedure = (name: string) => {
    const newSelected = new Set(selectedProcedures);
    if (newSelected.has(name)) {
      newSelected.delete(name);
    } else {
      newSelected.add(name);
    }
    setSelectedProcedures(newSelected);
  };

  const handleConfirm = () => {
    const selectedProcs = procedures.filter(p => selectedProcedures.has(p.name));
    onConfirm(selectedProcs);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[80vh] flex flex-col">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-bold text-gray-900">
            Import Preview ({procedures.length} procedures)
          </h2>
          <button
            onClick={onCancel}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {hasDuplicates && (
          <div className="px-6 py-4 bg-yellow-50 border-b border-yellow-100">
            <div className="flex items-center space-x-2 text-yellow-800">
              <AlertCircle size={20} />
              <span>
                {duplicates.length} procedure(s) already exist. These will be updated
                if selected.
              </span>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-auto p-6">
          <div className="space-y-4">
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
              {procedures.map((procedure) => {
                const isDuplicate = existingProcedures.includes(procedure.name);
                const isSelected = selectedProcedures.has(procedure.name);

                return (
                  <div
                    key={procedure.name}
                    className={`
                      p-4 rounded-lg border transition-colors cursor-pointer
                      ${isSelected
                        ? 'border-blue-200 bg-blue-50 hover:bg-blue-100'
                        : 'border-gray-200 hover:bg-gray-50'
                      }
                    `}
                    onClick={() => toggleProcedure(procedure.name)}
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex-1">
                        <h3 className="font-medium text-gray-900">
                          {procedure.name}
                        </h3>
                        {isDuplicate && (
                          <span className="text-xs text-yellow-600">
                            Will update existing procedure
                          </span>
                        )}
                      </div>
                      <div className="flex-shrink-0">
                        <CheckCircle
                          size={20}
                          className={`transition-colors ${
                            isSelected ? 'text-blue-500' : 'text-gray-300'
                          }`}
                        />
                      </div>
                    </div>
                    <div className="space-y-1 text-sm text-gray-600">
                      <p>Category: {procedure.category}</p>
                      <p>Steps: {procedure.steps}</p>
                      <p>Duration: {procedure.duration}</p>
                      {procedure.riskLevel && (
                        <p>Risk Level: {procedure.riskLevel}</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-6 border-t bg-gray-50 flex justify-end space-x-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={selectedProcedures.size === 0}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Import {selectedProcedures.size} Procedure{selectedProcedures.size !== 1 ? 's' : ''}
          </button>
        </div>
      </div>
    </div>
  );
}