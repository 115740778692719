import React from 'react';
import { User, Mail, Building } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export function UserSettings() {
  const { currentUser } = useAuth();

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center space-x-2">
        <User className="text-indigo-600" size={20} />
        <span>User Profile</span>
      </h3>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="mt-1 flex items-center space-x-2">
            <Mail className="text-gray-400" size={16} />
            <span className="text-gray-900">{currentUser?.email}</span>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Organization</label>
          <div className="mt-1 flex items-center space-x-2">
            <Building className="text-gray-400" size={16} />
            <span className="text-gray-900">Mission Control</span>
          </div>
        </div>
      </div>
    </div>
  );
}