import React, { useState } from 'react';
import { Plus, Trash2, Copy, Edit2 } from 'lucide-react';
import { HarnessForm } from './HarnessForm';
import type { Harness } from '../types';

interface HarnessListProps {
  harnesses: Harness[];
  selectedHarnessId: string | null;
  onSelect: (id: string) => void;
  onAdd: (name: string, description?: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  onUpdate: (harness: Harness) => void;
}

export function HarnessList({
  harnesses,
  selectedHarnessId,
  onSelect,
  onAdd,
  onDelete,
  onDuplicate,
  onUpdate,
}: HarnessListProps) {
  const [showForm, setShowForm] = useState(false);
  const [editingHarness, setEditingHarness] = useState<Harness | null>(null);

  const handleEdit = (harness: Harness, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingHarness(harness);
  };

  const handleUpdate = (name: string, description?: string) => {
    if (!editingHarness) return;

    const updatedHarness: Harness = {
      ...editingHarness,
      name,
      description,
      updatedAt: new Date(),
    };

    onUpdate(updatedHarness);
    setEditingHarness(null);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-900">Harnesses</h2>
        <button
          onClick={() => setShowForm(true)}
          className="p-1.5 text-blue-600 hover:text-blue-700 transition-colors"
        >
          <Plus size={20} />
        </button>
      </div>

      {(showForm || editingHarness) && (
        <div className="mb-4">
          <HarnessForm
            harness={editingHarness}
            onSubmit={editingHarness ? handleUpdate : onAdd}
            onCancel={() => {
              setShowForm(false);
              setEditingHarness(null);
            }}
          />
        </div>
      )}

      <div className="space-y-2">
        {harnesses.map((harness) => (
          <div
            key={harness.id}
            onClick={() => onSelect(harness.id)}
            className={`
              relative group rounded-lg p-3 cursor-pointer transition-all
              ${selectedHarnessId === harness.id
                ? 'bg-blue-50 border-blue-200'
                : 'hover:bg-gray-50 border-transparent'
              } border
            `}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium text-gray-900">{harness.name}</h3>
                {harness.description && (
                  <p className="text-sm text-gray-600 mt-0.5">
                    {harness.description}
                  </p>
                )}
              </div>
              <div className="opacity-0 group-hover:opacity-100 flex space-x-2">
                <button
                  onClick={(e) => handleEdit(harness, e)}
                  className="p-1 text-gray-400 hover:text-blue-500 transition-all"
                  title="Edit harness"
                >
                  <Edit2 size={16} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDuplicate(harness.id);
                  }}
                  className="p-1 text-gray-400 hover:text-blue-500 transition-all"
                  title="Duplicate harness"
                >
                  <Copy size={16} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(harness.id);
                  }}
                  className="p-1 text-gray-400 hover:text-red-500 transition-all"
                  title="Delete harness"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          </div>
        ))}

        {harnesses.length === 0 && !showForm && (
          <p className="text-center text-gray-500 text-sm py-4">
            No harnesses added yet
          </p>
        )}
      </div>
    </div>
  );
}