import React from 'react';
import { WelcomeHeader } from './WelcomeHeader';
import { AssemblyStats } from './stats/AssemblyStats';
import { HarnessStats } from './stats/HarnessStats';
import { QualityOverview } from './stats/QualityOverview';
import type { Satellite, Harness } from '../../types';

interface WelcomeDashboardProps {
  satellites?: Satellite[];
  harnesses?: Harness[];
  currentUser: any;
}

export function WelcomeDashboard({ 
  satellites = [], 
  harnesses = [], 
  currentUser 
}: WelcomeDashboardProps) {
  return (
    <div className="space-y-6">
      <WelcomeHeader currentUser={currentUser} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <AssemblyStats satellites={satellites} />
        <HarnessStats harnesses={harnesses} />
        <QualityOverview satellites={satellites} />
      </div>
    </div>
  );
}