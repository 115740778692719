import { useState, useEffect } from 'react';
import { 
  collection,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  query,
  orderBy
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { handleOfflineState } from '../utils/offline';
import { convertTimestamp, sanitizeData } from '../utils/firestore';
import type { Harness } from '../types';

export function useHarnesses() {
  const { currentUser } = useAuth();
  const [harnesses, setHarnesses] = useState<Harness[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      setHarnesses([]);
      setIsLoading(false);
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const harnessesRef = collection(userRef, 'harnesses');
    const harnessesQuery = query(harnessesRef, orderBy('order', 'asc'));

    const unsubscribe = onSnapshot(
      harnessesQuery,
      (snapshot) => {
        const harnessData: Harness[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          try {
            harnessData.push({
              ...data,
              id: doc.id,
              harnesses: data.harnesses || [],
              electricalBoxes: (data.electricalBoxes || []).map((box: any) => ({
                ...box,
                harnesses: box.harnesses || [],
                ports: box.ports || [],
                createdAt: convertTimestamp(box.createdAt),
                updatedAt: convertTimestamp(box.updatedAt),
              })),
              connections: (data.connections || []).map((conn: any) => ({
                ...conn,
                createdAt: convertTimestamp(conn.createdAt),
                updatedAt: convertTimestamp(conn.updatedAt),
              })),
              createdAt: convertTimestamp(data.createdAt),
              updatedAt: convertTimestamp(data.updatedAt),
            } as Harness);
          } catch (err) {
            console.error('Error processing harness data:', err);
          }
        });
        setHarnesses(harnessData);
        setIsLoading(false);
        setError(null);
        setIsOffline(false);
      },
      (err) => {
        console.error('Firestore subscription error:', err);
        const isOfflineErr = handleOfflineState(err);
        setIsOffline(isOfflineErr);
        if (!isOfflineErr) {
          setError('Error loading harnesses. Please try again.');
        }
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const saveHarness = async (harness: Harness) => {
    if (!currentUser) {
      setError('You must be logged in to save harnesses');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const harnessRef = doc(collection(userRef, 'harnesses'), harness.id);

      const harnessData = sanitizeData({
        ...harness,
        harnesses: harness.harnesses || [],
        electricalBoxes: (harness.electricalBoxes || []).map(box => ({
          ...box,
          harnesses: box.harnesses || [],
          ports: box.ports || [],
          createdAt: box.createdAt,
          updatedAt: box.updatedAt,
        })),
        connections: (harness.connections || []).map(conn => ({
          ...conn,
          createdAt: conn.createdAt,
          updatedAt: conn.updatedAt,
        })),
        createdAt: harness.createdAt,
        updatedAt: new Date(),
      });

      await setDoc(harnessRef, harnessData);
      setError(null);
    } catch (err) {
      console.error('Error saving harness:', err);
      setError('Failed to save harness. Please try again.');
      throw err;
    }
  };

  const deleteHarness = async (harnessId: string) => {
    if (!currentUser) {
      setError('You must be logged in to delete harnesses');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const harnessRef = doc(collection(userRef, 'harnesses'), harnessId);
      await deleteDoc(harnessRef);

      // Update order of remaining harnesses
      const remainingHarnesses = harnesses
        .filter(h => h.id !== harnessId)
        .map((h, index) => ({
          ...h,
          order: index,
          updatedAt: new Date()
        }));

      // Save updated orders
      await Promise.all(
        remainingHarnesses.map(harness => saveHarness(harness))
      );

      setError(null);
    } catch (err) {
      console.error('Error deleting harness:', err);
      setError('Failed to delete harness. Please try again.');
      throw err;
    }
  };

  return {
    harnesses,
    isLoading,
    error,
    isOffline,
    saveHarness,
    deleteHarness,
  };
}