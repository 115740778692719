import Papa from 'papaparse';
import type { CSVProcedure } from '../types';

export async function parseCSV(csvContent: string): Promise<CSVProcedure[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(csvContent, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => {
        return header.trim().toLowerCase().replace(/\s+/g, '_');
      },
      complete: (results) => {
        if (results.errors.length > 0) {
          reject(new Error('Failed to parse CSV: ' + results.errors[0].message));
          return;
        }

        const procedures: CSVProcedure[] = results.data.map((row: any) => ({
          name: row.procedure_name?.trim(),
          description: row.description?.trim(),
          steps: parseInt(row.steps, 10),
          category: row.category?.trim(),
          duration: row.duration?.trim(),
          requiredResources: row.required_resources?.split(',').map((r: string) => r.trim()),
          prerequisites: row.prerequisites?.split(',').map((p: string) => p.trim()),
          notes: row.notes?.trim(),
          riskLevel: row.risk_level?.trim(),
          department: row.department?.trim(),
        }));

        resolve(procedures);
      },
      error: (error) => {
        reject(new Error('Failed to parse CSV: ' + error.message));
      },
    });
  });
}