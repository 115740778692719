import { useState, useEffect } from 'react';
import { 
  collection,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  Timestamp,
  query,
  orderBy
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { handleOfflineState } from '../utils/offline';
import { convertTimestamp, sanitizeData } from '../utils/firestore';
import type { Satellite } from '../types';

export function useFirestore() {
  const { currentUser } = useAuth();
  const [satellites, setSatellites] = useState<Satellite[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      setSatellites([]);
      setIsLoading(false);
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const satellitesRef = collection(userRef, 'satellites');
    const satellitesQuery = query(satellitesRef, orderBy('order', 'asc'));

    const unsubscribe = onSnapshot(
      satellitesQuery,
      (snapshot) => {
        const satelliteData: Satellite[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          try {
            satelliteData.push({
              ...data,
              id: doc.id,
              createdAt: convertTimestamp(data.createdAt),
              updatedAt: convertTimestamp(data.updatedAt),
              procedures: (data.procedures || []).map((p: any) => ({
                ...p,
                createdAt: convertTimestamp(p.createdAt),
                updatedAt: convertTimestamp(p.updatedAt),
              })),
              assemblies: (data.assemblies || []).map((a: any) => ({
                ...a,
                createdAt: convertTimestamp(a.createdAt),
                updatedAt: convertTimestamp(a.updatedAt),
                procedures: (a.procedures || []).map((p: any) => ({
                  ...p,
                  createdAt: convertTimestamp(p.createdAt),
                  updatedAt: convertTimestamp(p.updatedAt),
                })),
              })),
            } as Satellite);
          } catch (err) {
            console.error('Error processing satellite data:', err);
          }
        });
        setSatellites(satelliteData);
        setIsLoading(false);
        setError(null);
        setIsOffline(false);
      },
      (err) => {
        console.error('Firestore subscription error:', err);
        const isOfflineErr = handleOfflineState(err);
        setIsOffline(isOfflineErr);
        if (!isOfflineErr) {
          setError('Error loading assemblies. Please try again.');
        }
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const saveSatellite = async (satellite: Satellite) => {
    if (!currentUser) {
      setError('You must be logged in to save assemblies');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const satelliteRef = doc(collection(userRef, 'satellites'), satellite.id);

      const satelliteData = sanitizeData({
        ...satellite,
        createdAt: satellite.createdAt,
        updatedAt: new Date(),
        procedures: satellite.procedures?.map(p => ({
          ...p,
          createdAt: p.createdAt,
          updatedAt: p.updatedAt,
        })) || [],
        assemblies: satellite.assemblies?.map(a => ({
          ...a,
          createdAt: a.createdAt,
          updatedAt: a.updatedAt,
          procedures: a.procedures?.map(p => ({
            ...p,
            createdAt: p.createdAt,
            updatedAt: p.updatedAt,
          })) || [],
        })) || [],
      });

      await setDoc(satelliteRef, satelliteData);
      setError(null);
    } catch (err) {
      console.error('Error saving assembly:', err);
      setError('Failed to save assembly. Please try again.');
      throw err;
    }
  };

  const deleteSatellite = async (satelliteId: string) => {
    if (!currentUser) {
      setError('You must be logged in to delete assemblies');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const satelliteRef = doc(collection(userRef, 'satellites'), satelliteId);
      await deleteDoc(satelliteRef);

      // Update order of remaining satellites
      const remainingSatellites = satellites
        .filter(s => s.id !== satelliteId)
        .map((s, index) => ({
          ...s,
          order: index,
          updatedAt: new Date()
        }));

      // Save updated orders
      await Promise.all(
        remainingSatellites.map(satellite => saveSatellite(satellite))
      );

      setError(null);
    } catch (err) {
      console.error('Error deleting assembly:', err);
      setError('Failed to delete assembly. Please try again.');
      throw err;
    }
  };

  return {
    satellites,
    isLoading,
    error,
    isOffline,
    saveSatellite,
    deleteSatellite,
  };
}