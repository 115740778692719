import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { AssemblyForm } from './AssemblyForm';
import { ParentAssemblyForm } from './ParentAssemblyForm';
import { SortableAssemblyCard } from './SortableAssemblyCard';
import type { Assembly, AssemblyFormData, Procedure } from '../types';

interface AssemblyCanvasProps {
  procedures: Procedure[];
  assemblies: Assembly[];
  onUpdateAssemblies: (assemblies: Assembly[]) => void;
  selectedAssemblyId?: string | null;
  assemblyRefs?: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
}

export function AssemblyCanvas({
  procedures,
  assemblies,
  onUpdateAssemblies,
  selectedAssemblyId,
  assemblyRefs,
}: AssemblyCanvasProps) {
  const [showForm, setShowForm] = useState(false);
  const [editingAssembly, setEditingAssembly] = useState<Assembly | null>(null);

  const handleAddAssembly = (
    formData: AssemblyFormData,
    selectedAssemblies: string[],
    selectedProcedures: string[]
  ) => {
    const selectedProcs = procedures.filter((p) => selectedProcedures.includes(p.id));
    const newAssembly: Assembly = {
      ...formData,
      id: crypto.randomUUID(),
      procedures: selectedProcs,
      subAssemblies: assemblies
        .filter((a) => selectedAssemblies.includes(a.id))
        .map((a) => ({ id: a.id })),
      isParent: true,
      order: assemblies.length,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    onUpdateAssemblies([...assemblies, newAssembly]);
    setShowForm(false);
  };

  const handleUpdateAssembly = (
    formData: AssemblyFormData,
    selectedAssemblies: string[],
    selectedProcedures: string[]
  ) => {
    if (!editingAssembly) return;

    const selectedProcs = procedures.filter((p) => selectedProcedures.includes(p.id));
    const updatedAssembly: Assembly = {
      ...editingAssembly,
      ...formData,
      procedures: selectedProcs,
      subAssemblies: assemblies
        .filter((a) => selectedAssemblies.includes(a.id))
        .map((a) => ({ id: a.id })),
      updatedAt: new Date(),
    };

    onUpdateAssemblies(
      assemblies.map((a) => (a.id === updatedAssembly.id ? updatedAssembly : a))
    );
    setEditingAssembly(null);
  };

  const handleDeleteAssembly = (id: string) => {
    // Remove the assembly from any parent assemblies
    const updatedAssemblies = assemblies.map(assembly => ({
      ...assembly,
      subAssemblies: assembly.subAssemblies?.filter(sub => sub.id !== id)
    }));
    
    // Then remove the assembly itself
    onUpdateAssemblies(updatedAssemblies.filter(a => a.id !== id));
  };

  return (
    <div className="space-y-6">
      {(showForm || editingAssembly) && (
        <div className="mb-6">
          <ParentAssemblyForm
            assemblies={assemblies.filter((a) => a.id !== editingAssembly?.id)}
            procedures={procedures}
            assembly={editingAssembly}
            onSubmit={editingAssembly ? handleUpdateAssembly : handleAddAssembly}
            onCancel={() => {
              setShowForm(false);
              setEditingAssembly(null);
            }}
          />
        </div>
      )}

      {!showForm && !editingAssembly && (
        <div className="flex">
          <button
            onClick={() => setShowForm(true)}
            className="flex-1 p-4 border-2 border-dashed border-blue-300 rounded-lg text-blue-500 hover:border-blue-500 hover:text-blue-600 transition-colors flex items-center justify-center space-x-2"
          >
            <Plus size={20} />
            <span>Add Parent Assembly</span>
          </button>
        </div>
      )}

      {assemblies.length > 0 ? (
        <div className="grid gap-6">
          {assemblies.map((assembly) => (
            <div
              key={assembly.id}
              ref={(el) => {
                if (assemblyRefs) {
                  assemblyRefs.current[assembly.id] = el;
                }
              }}
            >
              <SortableAssemblyCard
                assembly={assembly}
                allAssemblies={assemblies}
                onDelete={handleDeleteAssembly}
                onEdit={(id) =>
                  setEditingAssembly(assemblies.find((a) => a.id === id) || null)
                }
                onReorderProcedures={() => {}}
                isSelected={selectedAssemblyId === assembly.id}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 text-gray-500">
          <p className="text-lg">No assemblies created yet.</p>
          <p className="text-sm mt-2">
            Create your first parent assembly using the button above.
          </p>
        </div>
      )}
    </div>
  );
}