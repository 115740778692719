import React from 'react';

interface StatCardProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

export function StatCard({ title, icon, children }: StatCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
        {icon}
      </div>
      <div className="space-y-4">
        {children}
      </div>
    </div>
  );
}