import React from 'react';
import { Wrench, CheckCircle2 } from 'lucide-react';
import type { Procedure } from '../../types';

interface ProcedureNodeProps {
  procedure: Procedure;
  level: number;
  onSelect?: (procedureId: string) => void;
  isSelected?: boolean;
  isHorizontal?: boolean;
}

export function ProcedureNode({ 
  procedure, 
  level, 
  onSelect, 
  isSelected, 
  isHorizontal 
}: ProcedureNodeProps) {
  const progress = (procedure.currentStep / procedure.totalSteps) * 100;

  return (
    <div 
      className={`
        rounded-lg p-3 bg-gray-50 border border-gray-100 relative
        cursor-pointer hover:bg-gray-100 transition-colors
        ${isSelected ? 'ring-2 ring-blue-500 shadow-sm' : ''}
        ${isHorizontal ? 'ml-4 mt-0' : 'ml-8 mt-2'}
      `}
      onClick={() => onSelect?.(procedure.id)}
    >
      <div className={`absolute ${isHorizontal ? 'left-0 top-1/2 -translate-y-1/2 -translate-x-4 w-4 border-t-2' : 'left-0 top-0 -translate-x-8 h-full border-l-2'} border-gray-200`} />
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <Wrench className="text-gray-400 flex-shrink-0" size={16} />
            {procedure.isComplete && (
              <CheckCircle2 
                className="text-green-500 absolute -top-1 -right-1 bg-white rounded-full" 
                size={12} 
              />
            )}
          </div>
          <div>
            <span className="text-sm font-medium text-gray-700">{procedure.name}</span>
            <div className="flex items-center space-x-2 mt-1">
              <span className="text-xs text-gray-500">
                Step {procedure.currentStep}/{procedure.totalSteps}
              </span>
              <div className="w-20 bg-gray-200 rounded-full h-1.5">
                <div
                  className={`h-1.5 rounded-full transition-all duration-300 ${
                    procedure.isComplete ? 'bg-green-500' : 'bg-blue-500'
                  }`}
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}