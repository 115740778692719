import React, { useState, useEffect } from 'react';
import { X, Circle, Square } from 'lucide-react';
import type { Port } from '../types';

interface PortFormProps {
  port?: Port | null;
  onSubmit: (name: string, type: Port['type'], position: Port['position'], shape: Port['shape'], color: Port['color'], description?: string) => void;
  onCancel: () => void;
}

const SHAPES = [
  { id: 'circle', icon: Circle, label: 'Circular' },
  { id: 'rectangle', icon: Square, label: 'Rectangle' },
] as const;

const COLORS = [
  { id: 'green', label: 'Green', value: '#10B981' },
  { id: 'blue', label: 'Blue', value: '#3B82F6' },
  { id: 'black', label: 'Black', value: '#1F2937' },
  { id: 'silver', label: 'Silver', value: '#9CA3AF' },
] as const;

export function PortForm({ port, onSubmit, onCancel }: PortFormProps) {
  const [name, setName] = useState(port?.name || '');
  const [type, setType] = useState<Port['type']>(port?.type || 'bidirectional');
  const [position, setPosition] = useState<Port['position']>(port?.position || 'left');
  const [shape, setShape] = useState<Port['shape']>(port?.shape || 'circle');
  const [color, setColor] = useState<Port['color']>(port?.color || 'blue');
  const [description, setDescription] = useState(port?.description || '');

  useEffect(() => {
    if (port) {
      setName(port.name);
      setType(port.type);
      setPosition(port.position);
      setShape(port.shape || 'circle');
      setColor(port.color || 'blue');
      setDescription(port.description || '');
    }
  }, [port]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(name.trim(), type, position, shape, color, description.trim() || undefined);
    setName('');
    setType('bidirectional');
    setPosition('left');
    setShape('circle');
    setColor('blue');
    setDescription('');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">
          {port ? 'Edit Port' : 'Add Port'}
        </h2>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Port Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter port name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Port Type
          </label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value as Port['type'])}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          >
            <option value="input">Input</option>
            <option value="output">Output</option>
            <option value="bidirectional">Bidirectional</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Position
          </label>
          <select
            value={position}
            onChange={(e) => setPosition(e.target.value as Port['position'])}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          >
            <option value="top">Top</option>
            <option value="right">Right</option>
            <option value="bottom">Bottom</option>
            <option value="left">Left</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Shape
          </label>
          <div className="grid grid-cols-2 gap-4">
            {SHAPES.map(({ id, icon: Icon, label }) => (
              <button
                key={id}
                type="button"
                onClick={() => setShape(id)}
                className={`p-3 rounded-lg border-2 transition-colors ${
                  shape === id
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="flex items-center justify-center space-x-2">
                  <Icon
                    size={20}
                    className={shape === id ? 'text-blue-500' : 'text-gray-500'}
                  />
                  <span className="text-sm text-gray-600">{label}</span>
                </div>
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Color
          </label>
          <div className="grid grid-cols-2 gap-4">
            {COLORS.map(({ id, label, value }) => (
              <button
                key={id}
                type="button"
                onClick={() => setColor(id)}
                className={`p-3 rounded-lg border-2 transition-colors ${
                  color === id
                    ? 'border-blue-500'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <div className="flex items-center justify-center space-x-2">
                  <div
                    className="w-4 h-4 rounded"
                    style={{ backgroundColor: value }}
                  />
                  <span className="text-sm text-gray-600">{label}</span>
                </div>
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description (Optional)
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            rows={3}
            placeholder="Enter port description"
          />
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {port ? 'Update Port' : 'Add Port'}
          </button>
        </div>
      </form>
    </div>
  );
}